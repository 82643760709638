.fixedSellingPriceBuyDownAll {
  padding: 13.3333333333vw 0;
}
.fixedSellingPriceBuyDownAll .descSection {
  margin-bottom: 8vw;
  margin-top: -10.6666666667vw;
}
.fixedSellingPriceBuyDownAll .descSection p {
  font-family: Inter;
  font-size: 5.3333333333vw;
  font-weight: 700;
  line-height: 8vw;
  color: rgb(115, 80, 159);
  width: auto;
  text-align: center;
}
.fixedSellingPriceBuyDownAll .descSectionDown {
  margin-top: 8vw;
}
.fixedSellingPriceBuyDownAll .descSectionDown p {
  font-family: Inter;
  font-size: 4.2666666667vw;
  font-weight: 400;
  line-height: 5.8666666667vw;
  color: rgb(115, 80, 159);
  width: auto;
  text-align: center;
}
.fixedSellingPriceBuyDownAll .cardItems {
  margin-top: 10.6666666667vw;
  display: flex;
  flex-direction: column;
  gap: 6.9333333333vw;
}
.fixedSellingPriceBuyDownAll .cardItems .contentBox {
  width: 100%;
  background-color: rgba(137, 69, 236, 0.1);
  margin: 0 auto;
  text-align: center;
  border-radius: 10.6666666667vw;
  padding: 10.6666666667vw 5.3333333333vw;
  transition: all 0.3s ease-in-out;
  border: 3px solid white;
  cursor: pointer;
}
.fixedSellingPriceBuyDownAll .cardItems .contentBox .imageSectiom {
  width: 18.1333333333vw;
  height: 18.1333333333vw;
  padding: 2.6666666667vw;
  margin: 0 auto;
  background-color: rgb(137, 69, 236);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}
.fixedSellingPriceBuyDownAll .cardItems .contentBox .imageSectiom img {
  width: 8.5333333333vw;
  height: auto;
}
.fixedSellingPriceBuyDownAll .cardItems .contentBox h2 {
  font-family: Inter;
  font-size: 4.8vw;
  font-weight: 800;
  line-height: 5.8666666667vw;
  color: rgb(115, 80, 159);
  margin: 6.9333333333vw 0;
}
.fixedSellingPriceBuyDownAll .cardItems .contentBox p {
  font-family: Inter;
  font-size: 4.5333333333vw;
  font-weight: 400;
  line-height: 5.3333333333vw;
  color: rgb(115, 80, 159);
  width: 69.8666666667vw;
  margin: 0 auto;
}
.fixedSellingPriceBuyDownAll .cardItems .contentBox:hover {
  background: white;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 80px rgba(137, 69, 236, 0.2);
  transition: all 0.3s ease-in-out;
}
.fixedSellingPriceBuyDownAll .cardItems .contentBox:hover .imageSectiom {
  background: rgb(255, 0, 121);
  transition: all 0.3s ease-in-out;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .fixedSellingPriceBuyDownAll {
    padding: 5.9952038369vw 0;
  }
  .fixedSellingPriceBuyDownAll .descSection {
    margin-bottom: 3.5971223022vw;
  }
  .fixedSellingPriceBuyDownAll .descSection p {
    width: auto;
    margin: 0 auto;
    font-size: 2.6378896882vw;
    margin-bottom: 0.7194244604vw;
    line-height: 3.1175059952vw;
  }
  .fixedSellingPriceBuyDownAll .descSectionDown {
    margin-top: 4.7961630695vw;
  }
  .fixedSellingPriceBuyDownAll .descSectionDown p {
    width: auto;
    margin: 0 auto;
    font-size: 2.3980815348vw;
    margin-bottom: 0.7194244604vw;
    line-height: 3.1175059952vw;
  }
  .fixedSellingPriceBuyDownAll .cardItems {
    margin-top: 4.7961630695vw;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.4388489209vw;
  }
  .fixedSellingPriceBuyDownAll .cardItems .contentBox {
    width: auto;
    border-radius: 4.7961630695vw;
    padding: 4.7961630695vw 2.3980815348vw;
    margin: 0;
  }
  .fixedSellingPriceBuyDownAll .cardItems .contentBox .imageSectiom {
    width: 8.1534772182vw;
    height: 8.1534772182vw;
    padding: 1.1990407674vw;
  }
  .fixedSellingPriceBuyDownAll .cardItems .contentBox .imageSectiom img {
    width: 3.8369304556vw;
  }
  .fixedSellingPriceBuyDownAll .cardItems .contentBox h2 {
    font-size: 2.1582733813vw;
    line-height: 2.6378896882vw;
    margin: 3.1175059952vw 0;
  }
  .fixedSellingPriceBuyDownAll .cardItems .contentBox p {
    font-size: 2.0383693046vw;
    line-height: 2.3980815348vw;
    width: 31.4148681055vw;
  }
}
@media (min-width: 1024px) {
  .fixedSellingPriceBuyDownAll {
    padding: 2.6041666667vw 0;
  }
  .fixedSellingPriceBuyDownAll .descSection {
    margin-bottom: 1.0416666667vw;
    margin-top: -4.1666666667vw;
  }
  .fixedSellingPriceBuyDownAll .descSection p {
    text-align: center;
    font-size: 1.7708333333vw;
    line-height: 2.0833333333vw;
    width: auto;
    margin: 2.0833333333vw auto 0vw;
  }
  .fixedSellingPriceBuyDownAll .descSectionDown {
    margin-top: 2.0833333333vw;
  }
  .fixedSellingPriceBuyDownAll .descSectionDown p {
    text-align: center;
    font-size: 1.3541666667vw;
    line-height: 2.0833333333vw;
    width: auto;
    margin: 2.0833333333vw auto 0vw;
  }
  .fixedSellingPriceBuyDownAll .cardItems {
    margin-top: 2.0833333333vw;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.625vw;
  }
  .fixedSellingPriceBuyDownAll .cardItems .contentBox {
    width: calc(33.3333333333% - 0.625vw);
    border-radius: 2.0833333333vw;
    padding: 2.0833333333vw 1.0416666667vw;
    margin: 0;
  }
  .fixedSellingPriceBuyDownAll .cardItems .contentBox .imageSectiom {
    width: 4.0625vw;
    height: 4.0625vw;
    padding: 0.7291666667vw;
  }
  .fixedSellingPriceBuyDownAll .cardItems .contentBox .imageSectiom img {
    width: 1.875vw;
  }
  .fixedSellingPriceBuyDownAll .cardItems .contentBox h2 {
    font-size: 1.0416666667vw;
    line-height: 1.1458333333vw;
    margin: 1.3541666667vw 0;
  }
  .fixedSellingPriceBuyDownAll .cardItems .contentBox p {
    font-size: 0.9895833333vw;
    line-height: 1.3541666667vw;
    width: auto;
    margin: 0 auto;
  }
}