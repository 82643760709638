.smartViewingSection {
  padding: 13.3333333333vw 0;
}
.smartViewingSection p.subTitle {
  font-family: Inter;
  font-size: 5.3333333333vw;
  font-weight: 500;
  margin-bottom: 1.6vw;
  text-align: center;
  color: rgb(76, 41, 181);
}
.smartViewingSection .titleSection {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 12px;
  margin-bottom: 1.6vw;
}
.smartViewingSection .titleSection h2 {
  text-align: center;
  font-family: Inter;
  font-size: 7.4666666667vw;
  font-weight: 700;
  color: rgb(76, 41, 181);
  width: 86.6666666667vw;
}
.smartViewingSection .titleSection img {
  display: none;
  width: auto;
  height: 4.2666666667vw;
}
.smartViewingSection .descSection {
  margin-bottom: 8vw;
}
.smartViewingSection .descSection p {
  text-align: center;
  font-size: 4.8vw;
  font-family: Inter;
  font-weight: 400;
  color: rgb(115, 80, 159);
}
.smartViewingSection .descSection p span {
  font-weight: 700;
}
.smartViewingSection p {
  font-size: 2.8776978417vw;
  margin-bottom: 7.1942446043vw;
}
.smartViewingSection .cardItems {
  width: 66.6666666667vw;
  margin: 0 auto;
  padding-top: 10.6666666667vw;
}
.smartViewingSection .cardItems .contenCard {
  background: linear-gradient(to top left, rgba(137, 69, 236, 0.2), rgba(137, 69, 236, 0));
  border-radius: 10.6666666667vw;
  padding: 5.3333333333vw;
  height: 45.3333333333vw;
  margin-bottom: 18.6666666667vw;
  transition: all 0.3s ease-in-out;
  border: 3px solid white;
  cursor: pointer;
}
.smartViewingSection .cardItems .contenCard p {
  font-family: Inter;
  font-size: 4.2666666667vw;
  font-weight: 400;
  color: rgb(76, 41, 181);
  text-align: center;
  width: 53.3333333333vw;
}
.smartViewingSection .cardItems .contenCard .imageSectiom {
  position: relative;
  top: -11.7333333333vw;
  margin-bottom: -6.9333333333vw;
  width: 18.1333333333vw;
  height: 18.1333333333vw;
  border: 2px solid white;
  border-radius: 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(137, 69, 236);
  transition: all 0.3s ease-in-out;
}
.smartViewingSection .cardItems .contenCard .imageSectiom:nth-child(3) {
  margin-bottom: -5.3333333333vw;
}
.smartViewingSection .cardItems .contenCard .imageSectiom img {
  width: 8.5333333333vw;
  height: auto;
}
.smartViewingSection .cardItems .contenCard:hover {
  background: white;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 80px rgba(137, 69, 236, 0.2);
  transition: all 0.3s ease-in-out;
}
.smartViewingSection .cardItems .contenCard:hover .imageSectiom {
  background: rgb(255, 0, 121);
  transition: all 0.3s ease-in-out;
}
.smartViewingSection .scrollPointImage {
  display: none;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .smartViewingSection {
    padding: 5.9952038369vw 0;
  }
  .smartViewingSection p.subTitle {
    font-size: 2.3980815348vw;
    margin-bottom: 0.7194244604vw;
  }
  .smartViewingSection .titleSection {
    margin-bottom: 0.7194244604vw;
    align-items: center;
    gap: 0;
  }
  .smartViewingSection .titleSection h2 {
    font-size: 4.1966426859vw;
    width: 31.175059952vw;
  }
  .smartViewingSection .titleSection img {
    display: block;
    width: 4.3165467626vw;
    height: auto;
  }
  .smartViewingSection .descSection {
    margin-bottom: 3.5971223022vw;
  }
  .smartViewingSection .descSection p {
    font-size: 2.6378896882vw;
    width: 60.071942446vw;
    margin: 0 auto;
  }
  .smartViewingSection .cardItems {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 4.7961630695vw;
    gap: 7.1942446043vw 5.9952038369vw;
    margin-top: 7.1942446043vw;
  }
  .smartViewingSection .cardItems .contenCard {
    border-radius: 4.7961630695vw;
    padding: 2.3980815348vw;
    height: 20.3836930456vw;
    margin-bottom: 0vw;
    width: calc(50% - 30px);
  }
  .smartViewingSection .cardItems .contenCard p {
    font-size: 1.9184652278vw;
    width: 23.9808153477vw;
  }
  .smartViewingSection .cardItems .contenCard .imageSectiom {
    top: -5.2757793765vw;
    margin-bottom: -3.5971223022vw;
    width: 8.1534772182vw;
    height: 8.1534772182vw;
    border-radius: 1.7985611511vw;
  }
  .smartViewingSection .cardItems .contenCard .imageSectiom img {
    width: 3.8369304556vw;
  }
  .smartViewingSection .scrollPointImage {
    display: block;
  }
  .smartViewingSection .scrollPointImage img.Line {
    width: 100%;
    height: 5px;
  }
  .smartViewingSection .scrollPointImage img.pointer {
    margin-top: -1.4388489209vw;
  }
  .smartViewingSection .scrollPointImage img.pointer.goRight {
    transform: translateX(74.3405275779vw);
    transition: transform 0.7s ease-in;
  }
}
@media (min-width: 1024px) {
  .smartViewingSection {
    padding: 2.6041666667vw 0;
  }
  .smartViewingSection p.subTitle {
    font-size: 1.0416666667vw;
    margin-bottom: 0.3125vw;
    text-align: left;
  }
  .smartViewingSection .titleSection {
    margin-bottom: 0.3125vw;
    justify-content: center;
    align-items: center;
    justify-content: start;
    gap: 0;
  }
  .smartViewingSection .titleSection h2 {
    font-size: 2.4479166667vw;
    width: 17.7083333333vw;
    text-align: left;
  }
  .smartViewingSection .titleSection img {
    display: block;
    width: 2.0833333333vw;
    height: auto;
    margin-top: -10px;
  }
  .smartViewingSection .descSection {
    margin-bottom: 1.5625vw;
  }
  .smartViewingSection .descSection p {
    font-size: 1.4583333333vw;
    text-align: start;
  }
  .smartViewingSection .cardItems {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0;
    gap: 0.7291666667vw;
    margin-bottom: 3.125vw;
    padding-top: 2.0833333333vw;
  }
  .smartViewingSection .cardItems .contenCard {
    border-radius: 2.0833333333vw;
    padding: 1.0416666667vw 2.0833333333vw;
    height: 8.8541666667vw;
    margin-bottom: 0vw;
    width: 20%;
    height: 9.8958333333vw;
    position: relative;
  }
  .smartViewingSection .cardItems .contenCard p {
    font-size: 0.9375vw;
    width: 10.4166666667vw;
    margin: 0 auto 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .smartViewingSection .cardItems .contenCard .imageSectiom {
    top: -2.5vw;
    margin-bottom: -1.0416666667vw;
    width: 4.2708333333vw;
    height: 4.2708333333vw;
    border-radius: 0.78125vw;
  }
  .smartViewingSection .cardItems .contenCard .imageSectiom img {
    width: 1.6666666667vw;
  }
  .smartViewingSection .scrollPointImage {
    display: block;
  }
  .smartViewingSection .scrollPointImage img.Line {
    width: 100%;
    height: 5px;
  }
  .smartViewingSection .scrollPointImage img.pointer {
    margin-top: -0.78125vw;
    cursor: pointer;
  }
  .smartViewingSection .scrollPointImage img.pointer.goRight {
    transform: translateX(78.125vw);
    transition: transform 0.7s ease-in;
  }
}