.buyingWithTheGunuineSection {
  padding: 26.6666666667vw 0 0vw;
}
.buyingWithTheGunuineSection .flexContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.buyingWithTheGunuineSection .flexContent .leftSection img {
  width: 100%;
  height: auto;
}
.buyingWithTheGunuineSection .flexContent .rightSection {
  text-align: center;
}
.buyingWithTheGunuineSection .flexContent .rightSection h2 {
  font-family: Inter;
  font-size: 6.9333333333vw;
  font-weight: 700;
  line-height: 12.8vw;
  color: rgb(76, 41, 181);
  margin: 6.4vw 0;
}
.buyingWithTheGunuineSection .flexContent .rightSection p {
  font-family: Inter;
  font-size: 4.8vw;
  font-weight: 400;
  line-height: 5.8666666667vw;
  color: rgb(115, 80, 159);
  width: 86.6666666667vw;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .buyingWithTheGunuineSection {
    padding: 11.9904076739vw 0 5.9952038369vw;
  }
  .buyingWithTheGunuineSection .flexContent .rightSection h2 {
    font-size: 4.1966426859vw;
    line-height: 5.035971223vw;
    margin: 3.8369304556vw 0 2.8776978417vw 0;
  }
  .buyingWithTheGunuineSection .flexContent .rightSection p {
    font-size: 2.6378896882vw;
    line-height: 3.1175059952vw;
    width: 59.9520383693vw;
  }
}
@media (min-width: 1024px) {
  .buyingWithTheGunuineSection {
    padding: 8.8541666667vw 0 5.2083333333vw;
  }
  .buyingWithTheGunuineSection .flexContent {
    flex-direction: row;
    justify-content: space-between;
  }
  .buyingWithTheGunuineSection .flexContent .leftSection {
    width: 45%;
  }
  .buyingWithTheGunuineSection .flexContent .rightSection {
    text-align: left;
    width: 50%;
  }
  .buyingWithTheGunuineSection .flexContent .rightSection h2 {
    font-size: 2.4479166667vw;
    line-height: 2.9166666667vw;
    margin: 0;
  }
  .buyingWithTheGunuineSection .flexContent .rightSection p {
    margin-top: 2.0833333333vw;
    font-size: 1.1458333333vw;
    line-height: 1.3541666667vw;
    width: 30.8333333333vw;
  }
}