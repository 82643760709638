.transparentCommunicationSection {
  padding: 5.3333333333vw 0;
}
.transparentCommunicationSection p.subTitle {
  font-family: Inter;
  font-size: 5.3333333333vw;
  font-weight: 500;
  margin-bottom: 1.6vw;
  text-align: center;
  color: rgb(76, 41, 181);
}
.transparentCommunicationSection .titleSection {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 12px;
  margin-bottom: 1.6vw;
}
.transparentCommunicationSection .titleSection h2 {
  text-align: center;
  font-family: Inter;
  font-size: 7.4666666667vw;
  font-weight: 700;
  color: rgb(76, 41, 181);
}
.transparentCommunicationSection .titleSection img {
  display: none;
  width: auto;
  height: 4.2666666667vw;
}
.transparentCommunicationSection .descSection {
  margin-bottom: 8vw;
}
.transparentCommunicationSection .descSection p {
  text-align: center;
  font-size: 4.8vw;
  font-family: Inter;
  font-weight: 500;
  color: rgb(115, 80, 159);
}
.transparentCommunicationSection .descSection p span {
  font-weight: 700;
}
.transparentCommunicationSection .list {
  box-shadow: 0 0 67px rgba(137, 69, 236, 0.17);
  height: 186.6666666667vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(rgba(137, 69, 236, 0.04), rgba(137, 69, 236, 0));
  border: 3px solid white;
  border-radius: 32px;
}
.transparentCommunicationSection .list ul {
  width: 80vw;
}
.transparentCommunicationSection .list ul li {
  color: rgb(115, 80, 159);
  font-family: Inter;
  font-size: 4.2666666667vw;
  font-weight: 500;
  margin-bottom: 3.2vw;
  line-height: 12.8vw;
}
.transparentCommunicationSection .list ul li span {
  font-weight: 700;
}
.transparentCommunicationSection .list .animationShowSendImage {
  display: flex;
  align-items: end;
  justify-content: center;
  gap: 5.3333333333vw;
  opacity: 0;
}
.transparentCommunicationSection .list .animationShowSendImage img:first-child {
  width: 21.3333333333vw;
  height: 16vw;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
}
.transparentCommunicationSection .list .animationShowSendImage img:nth-child(2),
.transparentCommunicationSection .list .animationShowSendImage img:nth-child(3) {
  width: 21.3333333333vw;
  height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
}
.transparentCommunicationSection .list .animationShowSendImage.showAnimation {
  opacity: 1;
}
.transparentCommunicationSection .list .animationShowSendImage.showAnimation img:nth-child(1) {
  opacity: 0;
  animation: showImageOne 1.5s forwards;
}
.transparentCommunicationSection .list .animationShowSendImage.showAnimation img:nth-child(2) {
  opacity: 0;
  animation: showImageTwo 1.5s forwards;
  animation-delay: 1.5s;
}
.transparentCommunicationSection .list .animationShowSendImage.showAnimation img:nth-child(3) {
  opacity: 0;
  animation: showImageThree 1.5s forwards;
  animation-delay: 3s;
}
@keyframes showImageOne {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes showImageTwo {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes showImageThree {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .transparentCommunicationSection {
    padding: 5.9952038369vw 0;
  }
  .transparentCommunicationSection p.subTitle {
    font-size: 2.3980815348vw;
    margin-bottom: 0.7194244604vw;
  }
  .transparentCommunicationSection .titleSection {
    margin-bottom: 0.7194244604vw;
  }
  .transparentCommunicationSection .titleSection h2 {
    font-size: 4.1966426859vw;
  }
  .transparentCommunicationSection .titleSection img {
    display: block;
    height: 3.1175059952vw;
  }
  .transparentCommunicationSection .descSection {
    margin-bottom: 3.5971223022vw;
  }
  .transparentCommunicationSection .descSection p {
    font-size: 2.6378896882vw;
  }
  .transparentCommunicationSection .list {
    height: 77.9376498801vw;
  }
  .transparentCommunicationSection .list ul {
    width: 71.9424460432vw;
  }
  .transparentCommunicationSection .list ul li {
    font-size: 2.3980815348vw;
    margin-bottom: 2.3980815348vw;
    line-height: 5.7553956835vw;
  }
  .transparentCommunicationSection .list .animationShowSendImage {
    gap: 9.5923261391vw;
  }
  .transparentCommunicationSection .list .animationShowSendImage img:first-child {
    width: 14.3884892086vw;
    height: 9.5923261391vw;
  }
  .transparentCommunicationSection .list .animationShowSendImage img:nth-child(2),
  .transparentCommunicationSection .list .animationShowSendImage img:nth-child(3) {
    width: 14.3884892086vw;
  }
}
@media (min-width: 1024px) {
  .transparentCommunicationSection {
    padding: 2.6041666667vw 0;
  }
  .transparentCommunicationSection p.subTitle {
    font-size: 1.0416666667vw;
    margin-bottom: 0.3125vw;
    text-align: start;
  }
  .transparentCommunicationSection .titleSection {
    margin-bottom: 0.3125vw;
    justify-content: start;
    align-items: center;
  }
  .transparentCommunicationSection .titleSection h2 {
    font-size: 2.4479166667vw;
  }
  .transparentCommunicationSection .titleSection img {
    display: block;
    width: 2.0833333333vw;
    height: auto;
    margin-top: -10px;
  }
  .transparentCommunicationSection .descSection {
    margin-bottom: 1.5625vw;
  }
  .transparentCommunicationSection .descSection p {
    font-size: 1.4583333333vw;
    text-align: start;
  }
  .transparentCommunicationSection .list {
    height: 27.6041666667vw;
  }
  .transparentCommunicationSection .list ul {
    width: 72.9166666667vw;
  }
  .transparentCommunicationSection .list ul li {
    font-size: 1.1458333333vw;
    margin-bottom: 0vw;
    line-height: 2.5vw;
  }
  .transparentCommunicationSection .list .animationShowSendImage {
    gap: 6.25vw;
  }
  .transparentCommunicationSection .list .animationShowSendImage img:first-child {
    width: 7.5vw;
    height: 5vw;
  }
  .transparentCommunicationSection .list .animationShowSendImage img:nth-child(2),
  .transparentCommunicationSection .list .animationShowSendImage img:nth-child(3) {
    width: 7.5vw;
    height: 7.5vw;
  }
}