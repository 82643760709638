.advancedFilteringSection {
  padding: 13.3333333333vw 0;
}
.advancedFilteringSection p.subTitle {
  font-family: Inter;
  font-size: 5.3333333333vw;
  font-weight: 500;
  margin-bottom: 1.6vw;
  text-align: center;
  color: rgb(76, 41, 181);
}
.advancedFilteringSection .titleSection {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 12px;
  margin-bottom: 1.6vw;
}
.advancedFilteringSection .titleSection h2 {
  text-align: center;
  font-family: Inter;
  font-size: 7.4666666667vw;
  font-weight: 700;
  color: rgb(76, 41, 181);
  width: 86.6666666667vw;
}
.advancedFilteringSection .titleSection img {
  display: none;
  width: auto;
  height: 4.2666666667vw;
}
.advancedFilteringSection .descSection {
  margin-bottom: 8vw;
}
.advancedFilteringSection .descSection p {
  text-align: center;
  font-size: 4.8vw;
  font-family: Inter;
  font-weight: 400;
  color: rgb(115, 80, 159);
}
.advancedFilteringSection .descSection p span {
  font-weight: 700;
}
.advancedFilteringSection .sectionImag {
  background-image: url("../../assets/sectionAdvancedFiltering.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 86.1333333333vw;
  height: 63.4666666667vw;
  margin: 0 auto;
  cursor: pointer;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .advancedFilteringSection {
    padding: 5.9952038369vw 0;
  }
  .advancedFilteringSection p.subTitle {
    font-size: 2.3980815348vw;
    margin-bottom: 0.7194244604vw;
  }
  .advancedFilteringSection .titleSection {
    margin-bottom: 0.7194244604vw;
    align-items: center;
    gap: 0;
  }
  .advancedFilteringSection .titleSection h2 {
    font-size: 4.1966426859vw;
    width: 38.8489208633vw;
  }
  .advancedFilteringSection .titleSection img {
    display: block;
    width: 3.5971223022vw;
    height: auto;
  }
  .advancedFilteringSection .descSection {
    margin-bottom: 3.5971223022vw;
  }
  .advancedFilteringSection .descSection p {
    font-size: 2.6378896882vw;
    width: 65.9472422062vw;
    margin: 0 auto;
  }
  .advancedFilteringSection .sectionImag {
    background-image: url("../../assets/sectionAdvancedFilteringTablet.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: auto;
    height: 70.8633093525vw;
    margin: 7.1942446043vw auto 0;
  }
}
@media (min-width: 1024px) {
  .advancedFilteringSection {
    padding: 2.6041666667vw 0;
  }
  .advancedFilteringSection p.subTitle {
    font-size: 1.0416666667vw;
    margin-bottom: 0.3125vw;
    text-align: left;
  }
  .advancedFilteringSection .titleSection {
    margin-bottom: 0.3125vw;
    justify-content: center;
    align-items: center;
    justify-content: start;
    gap: 0;
  }
  .advancedFilteringSection .titleSection h2 {
    font-size: 2.4479166667vw;
    width: 22.6041666667vw;
    text-align: left;
  }
  .advancedFilteringSection .titleSection img {
    display: block;
    width: 1.6666666667vw;
    height: auto;
    margin-top: -10px;
  }
  .advancedFilteringSection .descSection {
    margin-bottom: 1.5625vw;
  }
  .advancedFilteringSection .descSection p {
    font-size: 1.4583333333vw;
    text-align: start;
  }
  .advancedFilteringSection .sectionImag {
    background-image: url("../../assets/sectionAdvancedFilteringTablet.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 41.3020833333vw;
    height: 30.78125vw;
    margin: 3.125vw auto 0;
    transition: all 0.7s ease-in-out;
  }
  .advancedFilteringSection .sectionImag:hover {
    background-image: url("../../assets/sectionAdvancedFilteringTabletHover.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 41.3020833333vw;
    height: 30.78125vw;
    margin: 3.125vw auto 0;
    transition: all 0.7s ease-in-out;
  }
}