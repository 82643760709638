.stepField.buyingAndSellingQnextContactInformation .salutationSection {
  margin-bottom: 6.4vw;
}
.stepField.buyingAndSellingQnextContactInformation .salutationSection label {
  font-family: Inter;
  font-size: 4.8vw;
  font-weight: 400;
  color: rgb(115, 80, 159);
  margin-bottom: 1.6vw;
}
.stepField.buyingAndSellingQnextContactInformation .salutationSection .css-hlgwow, .stepField.buyingAndSellingQnextContactInformation .salutationSection .css-1dyz3mf {
  height: 21.3333333333vw;
  border-radius: 15px;
  padding: 0 4.2666666667vw;
}
.stepField.buyingAndSellingQnextContactInformation .salutationSection .css-13cymwt-control {
  border-radius: 15px;
}
.stepField.buyingAndSellingQnextContactInformation .salutationSection .css-13cymwt-control {
  border-color: rgba(76, 41, 181, 0.2);
}
.stepField.buyingAndSellingQnextContactInformation .salutationSection .css-1xc3v61-indicatorContainer {
  padding: 0 4.2666666667vw;
  color: rgb(76, 41, 181);
}
.stepField.buyingAndSellingQnextContactInformation .salutationSection .css-1u9des2-indicatorSeparator {
  display: none;
}
.stepField.buyingAndSellingQnextContactInformation .salutationSection .css-t3ipsp-control {
  box-shadow: none;
  border: 1px solid rgb(76, 41, 181);
  border-radius: 15px;
}
.stepField.buyingAndSellingQnextContactInformation .salutationSection .css-1wy0on6 {
  width: 16.2666666667vw;
  justify-content: center;
}
.stepField.buyingAndSellingQnextContactInformation .phonNmberSection input.form-control {
  width: 100%;
  border-radius: 15px;
  border: 1px solid rgba(76, 41, 181, 0.2);
  padding: 0 21.3333333333vw;
}
.stepField.buyingAndSellingQnextContactInformation .phonNmberSection input.form-control:focus {
  box-shadow: none;
}
.stepField.buyingAndSellingQnextContactInformation .phonNmberSection .selected-flag {
  background-color: white;
  width: 14.9333333333vw;
}
.stepField.buyingAndSellingQnextContactInformation .phonNmberSection .selected-flag .arrow {
  display: none;
}
.stepField.buyingAndSellingQnextContactInformation .phonNmberSection .react-tel-input .flag-dropdown {
  padding: 0 5.3333333333vw;
  background-color: transparent;
  border: none;
  top: 50%;
  transform: translateY(-50%);
}
.stepField.buyingAndSellingQnextContactInformation .phonNmberSection .react-tel-input .flag-dropdown.open .selected-flag {
  background-color: transparent;
  position: relative;
}
.stepField.buyingAndSellingQnextContactInformation .phonNmberSection .boxContnet {
  position: relative;
}
.stepField.buyingAndSellingQnextContactInformation .phonNmberSection .boxContnet img {
  position: absolute;
  top: 50%;
  left: 15%;
  transform: translateY(-50%);
}
.stepField.buyingAndSellingQnextContactInformation .inputSection {
  display: flex;
  flex-direction: column;
  margin-bottom: 6.4vw;
}
.stepField.buyingAndSellingQnextContactInformation .inputSection label {
  font-family: Inter;
  font-size: 4.8vw;
  font-weight: 400;
  color: rgb(115, 80, 159);
  margin-bottom: 1.6vw;
}
.stepField.buyingAndSellingQnextContactInformation .inputSection input {
  font-family: Inter;
  font-size: 4.8vw;
  font-weight: 400;
  color: rgb(115, 80, 159);
  border: 1px solid rgba(76, 41, 181, 0.2);
  border-radius: 15px;
  height: 21.3333333333vw;
  padding: 0 4.2666666667vw;
}
.stepField.buyingAndSellingQnextContactInformation .inputSection input:focus {
  border: 1px solid rgb(76, 41, 181);
  outline: none;
}
.stepField.buyingAndSellingQnextContactInformation .inputSection input::-moz-placeholder {
  color: rgba(76, 41, 181, 0.2);
}
.stepField.buyingAndSellingQnextContactInformation .inputSection input:-ms-input-placeholder {
  color: rgba(76, 41, 181, 0.2);
}
.stepField.buyingAndSellingQnextContactInformation .inputSection input::placeholder {
  color: rgba(76, 41, 181, 0.2);
}
.stepField.buyingAndSellingQnextContactInformation .approvalsSection p.titleSection {
  font-family: Inter;
  font-weight: 400;
  font-size: 4.8vw;
  line-height: 5.8666666667vw;
  text-align: left;
  color: rgb(115, 80, 159);
}
.stepField.buyingAndSellingQnextContactInformation .approvalsSection .custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.stepField.buyingAndSellingQnextContactInformation .approvalsSection .checkbox-input {
  display: none;
}
.stepField.buyingAndSellingQnextContactInformation .approvalsSection .checkbox {
  width: 20px;
  height: 20px;
  border: 2px solid rgb(225, 225, 226);
  border-radius: 4px;
  box-shadow: 0 5px 10px rgba(26, 26, 26, 0.08);
  position: relative;
  transition: background-color 0.3s;
  margin-right: 10px;
}
.stepField.buyingAndSellingQnextContactInformation .approvalsSection .checkbox-input:checked + .checkbox {
  background-color: rgb(76, 41, 181);
}
.stepField.buyingAndSellingQnextContactInformation .approvalsSection .checkbox-input:checked + .checkbox::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 5px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: translate(-50%, -50%) rotate(-45deg) scaleX(-1);
}
.stepField.buyingAndSellingQnextContactInformation .approvalsSection label {
  display: flex;
  align-items: center;
  gap: 3.2vw;
  margin-bottom: 5.3333333333vw;
}
.stepField.buyingAndSellingQnextContactInformation .approvalsSection label p {
  margin: 0;
  font-family: Inter;
  font-weight: 400;
  font-size: 4.5333333333vw;
  line-height: 5.3333333333vw;
  color: rgb(115, 80, 159);
  width: 90%;
}
.stepField.buyingAndSellingQnextContactInformation .btnSection .btnNext {
  margin-bottom: 5.3333333333vw;
  width: 100%;
  height: 21.3333333333vw;
  background-color: rgba(76, 41, 181, 0.3);
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8vw;
}
.stepField.buyingAndSellingQnextContactInformation .btnSection .btnNext p {
  font-family: Inter;
  font-size: 4.8vw;
  font-weight: 600;
  color: white;
  margin: 0;
}
.stepField.buyingAndSellingQnextContactInformation .btnSection .btnNext.activeNext {
  background-color: rgb(76, 41, 181);
}
.stepField.buyingAndSellingQnextContactInformation .btnSection .btnback {
  width: 100%;
  height: 21.3333333333vw;
  background-color: rgb(0, 0, 0);
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8vw;
}
.stepField.buyingAndSellingQnextContactInformation .btnSection .btnback p {
  font-family: Inter;
  font-size: 4.8vw;
  font-weight: 600;
  color: white;
  margin: 0;
}
.stepField.buyingAndSellingQnextContactInformation .btnSection .btnback img {
  transform: rotate(180deg);
}

@media (min-width: 768px) and (max-width: 1023px) {
  .stepField.buyingAndSellingQnextContactInformation.marginStep {
    margin-bottom: 7.8125vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .salutationSection {
    margin-bottom: 2.8776978417vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .salutationSection label {
    font-size: 2.1582733813vw;
    margin-bottom: 0.7194244604vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .salutationSection .css-hlgwow, .stepField.buyingAndSellingQnextContactInformation .salutationSection .css-1dyz3mf {
    height: 9.5923261391vw;
    padding: 0 1.9184652278vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .salutationSection .css-1xc3v61-indicatorContainer {
    padding: 0 1.9184652278vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .salutationSection .css-1wy0on6 {
    width: 7.3141486811vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .phonNmberSection input.form-control {
    padding: 0 9.5923261391vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .phonNmberSection .selected-flag {
    width: 6.7146282974vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .phonNmberSection .react-tel-input .flag-dropdown {
    padding: 0 2.3980815348vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .phonNmberSection .boxContnet {
    position: relative;
  }
  .stepField.buyingAndSellingQnextContactInformation .phonNmberSection .boxContnet img {
    position: absolute;
    top: 50%;
    left: 7%;
    transform: translateY(-50%);
  }
  .stepField.buyingAndSellingQnextContactInformation .inputSection {
    margin-bottom: 2.8776978417vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .inputSection label {
    font-size: 2.1582733813vw;
    margin-bottom: 0.7194244604vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .inputSection input {
    font-size: 2.1582733813vw;
    height: 9.5923261391vw;
    padding: 0 1.9184652278vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .approvalsSection p.titleSection {
    font-size: 2.1582733813vw;
    line-height: 2.6378896882vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .approvalsSection label {
    display: flex;
    align-items: center;
    gap: 1.4388489209vw;
    margin-bottom: 5.3333333333vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .approvalsSection label p {
    font-size: 2.0383693046vw;
    line-height: 2.3980815348vw;
    width: 90%;
  }
  .stepField.buyingAndSellingQnextContactInformation .btnSection .btnNext {
    margin-bottom: 2.3980815348vw;
    height: 9.5923261391vw;
    padding: 0 3.5971223022vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .btnSection .btnNext p {
    font-size: 2.1582733813vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .btnSection .btnback {
    height: 9.5923261391vw;
    padding: 0 3.5971223022vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .btnSection .btnback p {
    font-size: 2.1582733813vw;
  }
}
@media (min-width: 1024px) {
  .stepField.buyingAndSellingQnextContactInformation {
    padding-top: 5.2083333333vw;
    width: 41.6666666667vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .salutationSection {
    margin-bottom: 1.25vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .salutationSection label {
    font-size: 0.9375vw;
    margin-bottom: 0.3125vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .salutationSection .css-hlgwow, .stepField.buyingAndSellingQnextContactInformation .salutationSection .css-1dyz3mf {
    height: 4.1666666667vw;
    padding: 0 0.8333333333vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .salutationSection .css-1xc3v61-indicatorContainer {
    padding: 0 0.8333333333vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .salutationSection .css-1wy0on6 {
    width: 3.1770833333vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .phonNmberSection input.form-control {
    padding: 0 4.1666666667vw;
    width: 18.2291666667vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .phonNmberSection .selected-flag {
    width: 2.9166666667vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .phonNmberSection .react-tel-input .flag-dropdown {
    padding: 0 1.0416666667vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .phonNmberSection .boxContnet {
    position: relative;
  }
  .stepField.buyingAndSellingQnextContactInformation .phonNmberSection .boxContnet img {
    position: absolute;
    top: 50%;
    left: 17%;
    transform: translateY(-50%);
  }
  .stepField.buyingAndSellingQnextContactInformation .inputSection {
    margin-bottom: 1.25vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .inputSection label {
    font-size: 0.9375vw;
    margin-bottom: 0.3125vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .inputSection input {
    font-size: 0.9375vw;
    height: 4.1666666667vw;
    padding: 0 0.8333333333vw;
    width: 18.2291666667vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .approvalsSection p.titleSection {
    font-size: 0.9375vw;
    line-height: 1.1458333333vw;
    width: 100%;
  }
  .stepField.buyingAndSellingQnextContactInformation .approvalsSection label {
    display: flex;
    align-items: center;
    gap: 0.625vw;
    margin-bottom: 1.0416666667vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .approvalsSection label p {
    font-size: 0.8854166667vw;
    line-height: 1.0416666667vw;
    width: 80%;
  }
  .stepField.buyingAndSellingQnextContactInformation .btnSection .btnNext {
    margin-bottom: 1.0416666667vw;
    cursor: pointer;
    height: 4.1666666667vw;
    padding: 0 1.5625vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .btnSection .btnNext p {
    font-size: 0.9375vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .btnSection .btnback {
    cursor: pointer;
    height: 4.1666666667vw;
    padding: 0 1.5625vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .btnSection .btnback p {
    font-size: 0.9375vw;
  }
  .stepField.buyingAndSellingQnextContactInformation .flexSection {
    display: flex;
    justify-content: space-between;
  }
}