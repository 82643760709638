.scrollDownSection {
  padding: 0 0 13.3333333333vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.scrollDownSection a {
  text-decoration: none;
  text-align: center;
}
.scrollDownSection p {
  font-family: Poppins;
  font-size: 4.2666666667vw;
  font-weight: 500;
  line-height: 8.8vw;
  color: white;
  cursor: pointer;
}
.scrollDownSection img {
  width: 8vw;
  height: auto;
  cursor: pointer;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .scrollDownSection {
    padding: 3.5971223022vw 0;
  }
  .scrollDownSection p {
    font-size: 2.6378896882vw;
    line-height: 3.9568345324vw;
  }
  .scrollDownSection img {
    width: 4.7961630695vw;
  }
}
@media (min-width: 1024px) {
  .scrollDownSection {
    padding: 1.0416666667vw 0 2.6041666667vw;
  }
  .scrollDownSection p {
    font-size: 1.1458333333vw;
    line-height: 1.71875vw;
  }
  .scrollDownSection img {
    width: 2.0833333333vw;
  }
}