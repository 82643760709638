.ourPartnersSection {
  padding: 13.3333333333vw 0;
}
.ourPartnersSection .titleSection {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 12px;
  margin-bottom: 1.6vw;
}
.ourPartnersSection .titleSection h2 {
  position: relative;
  font-family: Inter;
  font-size: 8vw;
  font-weight: 700;
  line-height: normal;
  color: rgb(76, 41, 181);
  margin: 0 0 1.6vw 0;
}
.ourPartnersSection .titleSection h2 span {
  color: rgb(255, 0, 121);
}
.ourPartnersSection .titleSection h2::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 4px;
  background-color: rgb(76, 41, 181);
  bottom: 0vw;
}
.ourPartnersSection .titleSection img {
  display: none;
  width: auto;
  height: 4.2666666667vw;
}
.ourPartnersSection .boxContentImage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.6vw 5.3333333333vw;
  padding-top: 0vw;
}
.ourPartnersSection .boxContentImage img {
  width: calc(25% - 5.3333333333vw);
  height: auto;
  max-height: 16vw;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .ourPartnersSection {
    padding: 5.9952038369vw 0;
  }
  .ourPartnersSection .titleSection {
    margin-bottom: 0.7194244604vw;
    align-items: center;
    gap: 12px;
  }
  .ourPartnersSection .titleSection h2 {
    font-size: 4.1966426859vw;
    line-height: 5.035971223vw;
    margin: 1.1990407674vw 0 2.8776978417vw 0;
  }
  .ourPartnersSection .titleSection h2::before {
    bottom: -0.7194244604vw;
  }
  .ourPartnersSection .titleSection img {
    display: block;
    width: 4.3165467626vw;
    height: auto;
  }
  .ourPartnersSection .boxContentImage {
    gap: 2.3980815348vw;
    padding-top: 2.3980815348vw;
  }
  .ourPartnersSection .boxContentImage img {
    width: calc(20% - 2.3980815348vw);
    height: 7.1942446043vw;
  }
  .ourPartnersSection .boxContentImage img:nth-child(3) {
    height: 5.9952038369vw;
  }
}
@media (min-width: 1024px) {
  .ourPartnersSection {
    padding: 5.2083333333vw 0;
  }
  .ourPartnersSection .titleSection {
    margin-bottom: 0.3125vw;
    justify-content: center;
    align-items: center;
    justify-content: center;
  }
  .ourPartnersSection .titleSection h2 {
    font-size: 2.6041666667vw;
    width: auto;
    margin: 0;
  }
  .ourPartnersSection .titleSection h2::before {
    bottom: 0vw;
  }
  .ourPartnersSection .titleSection img {
    display: block;
    width: 2.5vw;
    height: auto;
    margin-top: 0;
  }
  .ourPartnersSection .boxContentImage {
    gap: 3.6458333333vw;
    padding-top: 2.6041666667vw;
    justify-content: center;
  }
  .ourPartnersSection .boxContentImage img {
    max-width: 7.8125vw;
    height: auto;
    max-height: 4.1666666667vw;
  }
}