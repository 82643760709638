.LuxuryMeetsConvenience {
  padding: 0;
}
.LuxuryMeetsConvenience p.subTitle {
  font-family: Inter;
  font-size: 4.8vw;
  font-weight: 500;
  margin-bottom: 0vw;
  text-align: center;
  color: rgb(115, 80, 159);
}
.LuxuryMeetsConvenience .titleSection {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 12px;
  margin-bottom: 1.6vw;
}
.LuxuryMeetsConvenience .titleSection h2 {
  font-family: Inter;
  font-size: 5.8666666667vw;
  font-weight: 700;
  line-height: normal;
  color: rgb(76, 41, 181);
  margin: 0 0 1.6vw 0;
  text-align: center;
}
.LuxuryMeetsConvenience .titleSection img {
  display: none;
  width: auto;
  height: 4.2666666667vw;
}
.LuxuryMeetsConvenience .descSection {
  margin-bottom: 8vw;
}
.LuxuryMeetsConvenience .descSection p {
  font-family: Inter;
  font-size: 4.2666666667vw;
  font-weight: 400;
  line-height: 5.8666666667vw;
  color: rgb(115, 80, 159);
  width: auto;
  text-align: center;
}
.LuxuryMeetsConvenience .descSection p span {
  font-weight: 700;
}
.LuxuryMeetsConvenience .cardItem {
  background-color: white;
  box-shadow: 0 0 67px rgba(137, 69, 236, 0.17);
  padding: 3.2vw;
  border-radius: 6.9333333333vw;
}
.LuxuryMeetsConvenience .cardItem .sizeBox {
  background: linear-gradient(to top left, rgb(76, 41, 181), rgba(106, 63, 236, 0.5));
  border-radius: 6.9333333333vw;
  cursor: pointer;
}
.LuxuryMeetsConvenience .cardItem .sizeBox .contentBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10.6666666667vw 0;
}
.LuxuryMeetsConvenience .cardItem .sizeBox .contentBox:nth-child(2), .LuxuryMeetsConvenience .cardItem .sizeBox .contentBox:nth-child(3) {
  padding: 5.3333333333vw 0 5.3333333333vw;
}
.LuxuryMeetsConvenience .cardItem .sizeBox .contentBox:nth-child(1) {
  border-top-left-radius: 6.9333333333vw;
  border-top-right-radius: 6.9333333333vw;
  padding: 10.6666666667vw 0 5.3333333333vw;
}
.LuxuryMeetsConvenience .cardItem .sizeBox .contentBox:nth-child(4) {
  border-bottom-left-radius: 6.9333333333vw;
  border-bottom-right-radius: 6.9333333333vw;
  padding: 5.3333333333vw 0 10.6666666667vw;
}
.LuxuryMeetsConvenience .cardItem .sizeBox .contentBox img {
  width: 12.8vw;
  height: auto;
}
.LuxuryMeetsConvenience .cardItem .sizeBox .contentBox h3 {
  font-family: Inter;
  font-size: 6.4vw;
  font-weight: 700;
  padding: 3.2vw 0;
  margin: 0;
  color: white;
  width: 80vw;
  text-align: center;
}
.LuxuryMeetsConvenience .cardItem .sizeBox .contentBox p {
  font-family: Inter;
  font-size: 4.2666666667vw;
  font-weight: 400;
  width: 90%;
  text-align: center;
  margin: 0;
  color: white;
  line-height: 5.3333333333vw;
}
.LuxuryMeetsConvenience .cardItem .sizeBox .contentBox .imageHover {
  width: 18.1333333333vw;
  height: 18.1333333333vw;
  border: 2px solid white;
  border-radius: 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.LuxuryMeetsConvenience .cardItem .sizeBox .contentBox .imageHover img {
  width: 8.5333333333vw;
  height: auto;
}
.LuxuryMeetsConvenience .cardItem .sizeBox .contentBox:hover {
  background-color: rgb(255, 0, 121);
}

@media (min-width: 768px) and (max-width: 1023px) {
  .LuxuryMeetsConvenience {
    padding: 0;
  }
  .LuxuryMeetsConvenience p.subTitle {
    font-size: 2.3980815348vw;
    margin-bottom: 0.7194244604vw;
  }
  .LuxuryMeetsConvenience .titleSection {
    margin-bottom: 0.7194244604vw;
    align-items: center;
    gap: 12px;
  }
  .LuxuryMeetsConvenience .titleSection h2 {
    font-size: 2.6378896882vw;
    line-height: 5.035971223vw;
    margin: 1.1990407674vw 0 2.8776978417vw 0;
  }
  .LuxuryMeetsConvenience .titleSection img {
    display: block;
    width: 4.3165467626vw;
    height: auto;
  }
  .LuxuryMeetsConvenience .descSection {
    margin-bottom: 3.5971223022vw;
  }
  .LuxuryMeetsConvenience .descSection p {
    width: auto;
    margin: 0 auto;
    font-size: 2.3980815348vw;
    margin-bottom: 0.7194244604vw;
    line-height: 3.1175059952vw;
  }
  .LuxuryMeetsConvenience .cardItem {
    padding: 1.4388489209vw;
    border-radius: 3.1175059952vw;
  }
  .LuxuryMeetsConvenience .cardItem .sizeBox {
    border-radius: 3.1175059952vw;
  }
  .LuxuryMeetsConvenience .cardItem .sizeBox .contentBox {
    padding: 4.7961630695vw 0;
  }
  .LuxuryMeetsConvenience .cardItem .sizeBox .contentBox:nth-child(2), .LuxuryMeetsConvenience .cardItem .sizeBox .contentBox:nth-child(3) {
    padding: inherit;
    padding: 2.3980815348vw 0 2.3980815348vw;
  }
  .LuxuryMeetsConvenience .cardItem .sizeBox .contentBox:nth-child(1) {
    border-top-left-radius: 3.1175059952vw;
    border-top-right-radius: 3.1175059952vw;
    padding: 4.7961630695vw 0 2.3980815348vw;
  }
  .LuxuryMeetsConvenience .cardItem .sizeBox .contentBox:nth-child(4) {
    border-bottom-left-radius: 3.1175059952vw;
    border-bottom-right-radius: 3.1175059952vw;
    padding: 2.3980815348vw 0 4.7961630695vw;
  }
  .LuxuryMeetsConvenience .cardItem .sizeBox .contentBox img {
    width: 8.1534772182vw;
  }
  .LuxuryMeetsConvenience .cardItem .sizeBox .contentBox h3 {
    font-size: 2.8776978417vw;
    padding: 1.4388489209vw 0;
    width: 100%;
  }
  .LuxuryMeetsConvenience .cardItem .sizeBox .contentBox p {
    font-size: 1.9184652278vw;
    width: 90%;
    line-height: 2.3980815348vw;
  }
  .LuxuryMeetsConvenience .cardItem .sizeBox .contentBox .imageHover {
    width: 8.1534772182vw;
    height: 8.1534772182vw;
    border-radius: 1.7985611511vw;
  }
  .LuxuryMeetsConvenience .cardItem .sizeBox .contentBox .imageHover img {
    width: 3.8369304556vw;
  }
}
@media (min-width: 1024px) {
  .LuxuryMeetsConvenience {
    padding: 0;
  }
  .LuxuryMeetsConvenience p.subTitle {
    font-size: 1.0416666667vw;
    margin-bottom: 0.625vw;
    text-align: start;
  }
  .LuxuryMeetsConvenience .titleSection {
    margin-bottom: 0.3125vw;
    justify-content: center;
    align-items: start;
    justify-content: start;
  }
  .LuxuryMeetsConvenience .titleSection h2 {
    font-size: 1.7708333333vw;
    width: auto;
    margin: 0;
  }
  .LuxuryMeetsConvenience .titleSection img {
    display: block;
    width: 2.0833333333vw;
    height: auto;
    margin-top: 0;
  }
  .LuxuryMeetsConvenience .descSection {
    margin-bottom: 1.0416666667vw;
  }
  .LuxuryMeetsConvenience .descSection p {
    text-align: left;
    font-size: 1.25vw;
    line-height: 2.0833333333vw;
    width: auto;
    margin: 1.0416666667vw auto 3.125vw;
  }
  .LuxuryMeetsConvenience .cardItem {
    padding: 0.625vw;
    border-radius: 2.6041666667vw;
  }
  .LuxuryMeetsConvenience .cardItem .sizeBox {
    border-radius: 2.6041666667vw;
    display: flex;
    justify-content: space-around;
    gap: 1.0416666667vw;
    height: 13.5416666667vw;
    align-items: start;
  }
  .LuxuryMeetsConvenience .cardItem .sizeBox .contentBox {
    padding: 2.0833333333vw 0;
    width: 25%;
    border-radius: 2.0833333333vw 2.0833333333vw 0 0;
  }
  .LuxuryMeetsConvenience .cardItem .sizeBox .contentBox img {
    width: 3.5416666667vw;
  }
  .LuxuryMeetsConvenience .cardItem .sizeBox .contentBox h3 {
    font-size: 1.25vw;
    padding: 0.625vw 0;
    width: 100%;
  }
  .LuxuryMeetsConvenience .cardItem .sizeBox .contentBox p {
    font-size: 0.9375vw;
    width: 70%;
    line-height: 1.0416666667vw;
  }
  .LuxuryMeetsConvenience .cardItem .sizeBox .contentBox:nth-child(2), .LuxuryMeetsConvenience .cardItem .sizeBox .contentBox:nth-child(3) {
    padding: 2.0833333333vw 0;
  }
  .LuxuryMeetsConvenience .cardItem .sizeBox .contentBox:nth-child(1) {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    border-bottom-left-radius: 2.0833333333vw;
    padding: 2.0833333333vw 0;
  }
  .LuxuryMeetsConvenience .cardItem .sizeBox .contentBox:nth-child(4) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-right-radius: 2.0833333333vw;
    padding: 2.0833333333vw 0;
  }
  .LuxuryMeetsConvenience .cardItem .sizeBox .contentBox .imageHover {
    width: 3.5416666667vw;
    height: 3.5416666667vw;
    border-radius: 0.78125vw;
  }
  .LuxuryMeetsConvenience .cardItem .sizeBox .contentBox .imageHover img {
    width: 1.6666666667vw;
  }
  .LuxuryMeetsConvenience .cardItem .sizeBox .contentBox:hover {
    padding: 0;
    position: relative;
    top: -2.6041666667vw;
    height: 16.1458333333vw;
    background-color: rgb(255, 0, 121);
    transition: height 0.3s ease-in-out;
  }
  .LuxuryMeetsConvenience .cardItem .sizeBox .contentBox:nth-child(2) p {
    width: 100%;
  }
}