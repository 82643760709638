.bannerGenuineSection .desktopBanner {
  display: none;
}
.bannerGenuineSection .mobileBanner {
  width: 100%;
  position: relative;
  z-index: -1;
  top: 2.6666666667vw;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .bannerGenuineSection .mobileBanner {
    top: 1.9184652278vw;
  }
}
@media (min-width: 1024px) {
  .bannerGenuineSection .mobileBanner {
    z-index: 1;
    top: 1.6786570743vw;
  }
}