.effortlessSelfListingSection {
  padding: 26.6666666667vw 0 0;
}
.effortlessSelfListingSection p.subTitle {
  font-family: Inter;
  font-size: 3.7333333333vw;
  font-weight: 500;
  margin-bottom: 1.6vw;
  text-align: center;
  color: rgb(76, 41, 181);
}
.effortlessSelfListingSection .titleSection {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 12px;
  margin-bottom: 1.6vw;
}
.effortlessSelfListingSection .titleSection h2 {
  font-family: Inter;
  font-size: 6.4vw;
  font-weight: 700;
  color: rgb(76, 41, 181);
}
.effortlessSelfListingSection .titleSection img {
  width: auto;
  height: 4.2666666667vw;
}
.effortlessSelfListingSection .descSection {
  margin-bottom: 8vw;
}
.effortlessSelfListingSection .descSection p {
  text-align: left;
  font-size: 4.2666666667vw;
  font-family: Inter;
  font-weight: 500;
  color: rgb(115, 80, 159);
}
.effortlessSelfListingSection .descSection p span {
  font-weight: 700;
}
.effortlessSelfListingSection .descDownSection {
  background: linear-gradient(to top left, rgba(137, 69, 236, 0.2), rgba(137, 69, 236, 0));
  border-radius: 5.3333333333vw;
  padding: 5.3333333333vw;
  height: 45.3333333333vw;
  width: 100%;
  margin-bottom: 18.6666666667vw;
  transition: all 0.3s ease-in-out;
  border: 2px solid rgb(168, 131, 232);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  animation: showText 2s linear forwards;
  animation-delay: 5s;
}
@keyframes showText {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.effortlessSelfListingSection .selfListing {
  margin-bottom: 10.6666666667vw;
  position: relative;
}
.effortlessSelfListingSection .selfListing .contentBox {
  display: flex;
  align-items: center;
  gap: 3.2vw;
  margin-bottom: 5.3333333333vw;
  position: relative;
}
.effortlessSelfListingSection .selfListing .contentBox::before {
  position: absolute;
  content: " ";
  background-image: url("../../assets/LineHight.svg");
  background-repeat: no-repeat;
  width: 2px;
  height: 5.8666666667vw;
  background-size: 100%;
  left: 3.7333333333vw;
  top: 7.2vw;
}
.effortlessSelfListingSection .selfListing .contentBox:last-child:before {
  background-image: url("../../assets/LineHight.svg");
  height: 11.7333333333vw;
}
.effortlessSelfListingSection .selfListing .contentBox img {
  width: 8vw;
  height: auto;
  position: relative;
}
.effortlessSelfListingSection .selfListing .contentBox p {
  margin: 0;
  text-align: center;
  font-size: 3.7333333333vw;
  font-family: Inter;
  font-weight: 500;
  color: rgb(115, 80, 159);
}
.effortlessSelfListingSection .selfListing::before {
  position: absolute;
  content: " ";
  width: 100%;
  height: 61.3333333333vw;
  bottom: -11.2vw;
  background-color: #fafafa;
  z-index: 2;
  animation: shrink 4s linear forwards;
  animation-delay: 1s;
}
@keyframes shrink {
  0% {
    height: 61.3333333333vw;
  }
  100% {
    height: 0;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .effortlessSelfListingSection {
    padding: 11.9904076739vw 0 0;
  }
  .effortlessSelfListingSection p.subTitle {
    font-size: 2.3980815348vw;
    margin-bottom: 0.7194244604vw;
  }
  .effortlessSelfListingSection .titleSection {
    margin-bottom: 0.7194244604vw;
  }
  .effortlessSelfListingSection .titleSection h2 {
    font-size: 4.1966426859vw;
  }
  .effortlessSelfListingSection .titleSection img {
    height: 1.9184652278vw;
  }
  .effortlessSelfListingSection .descSection {
    margin-bottom: 3.5971223022vw;
  }
  .effortlessSelfListingSection .descSection p {
    font-size: 2.1582733813vw;
  }
  .effortlessSelfListingSection .descDownSection {
    border-radius: 2.3980815348vw;
    padding: 2.3980815348vw;
    height: 20.3836930456vw;
    margin-bottom: 8.3932853717vw;
  }
  @keyframes showText {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .effortlessSelfListingSection .selfListing {
    margin-bottom: 4.7961630695vw;
  }
  .effortlessSelfListingSection .selfListing .contentBox {
    gap: 1.4388489209vw;
    margin-bottom: 2.3980815348vw;
  }
  .effortlessSelfListingSection .selfListing .contentBox::before {
    height: 2.6378896882vw;
    left: 2.1582733813vw;
    top: 4.3165467626vw;
  }
  .effortlessSelfListingSection .selfListing .contentBox img {
    width: 4.7961630695vw;
  }
  .effortlessSelfListingSection .selfListing .contentBox p {
    font-size: 2.3980815348vw;
  }
  .effortlessSelfListingSection .selfListing .contentBox:last-child:before {
    height: 5.2757793765vw;
  }
  .effortlessSelfListingSection .selfListing::before {
    position: absolute;
    content: " ";
    width: 100%;
    height: 31.175059952vw;
    bottom: -5.035971223vw;
    background-color: #fafafa;
    z-index: 2;
    animation: shrink 5s linear forwards;
    animation-delay: 1s;
  }
  @keyframes shrink {
    0% {
      height: 31.175059952vw;
    }
    100% {
      height: 0;
    }
  }
}
@media (min-width: 1024px) {
  .effortlessSelfListingSection {
    padding: 8.8541666667vw 0 5.2083333333vw;
  }
  .effortlessSelfListingSection p.subTitle {
    font-size: 1.0416666667vw;
    margin-bottom: 0.3125vw;
    text-align: start;
  }
  .effortlessSelfListingSection .titleSection {
    margin-bottom: 0.3125vw;
    justify-content: start;
    align-items: center;
  }
  .effortlessSelfListingSection .titleSection h2 {
    font-size: 2.4479166667vw;
  }
  .effortlessSelfListingSection .titleSection img {
    width: 1.6666666667vw;
    height: auto;
    margin-top: -10px;
  }
  .effortlessSelfListingSection .descSection {
    margin-bottom: 1.5625vw;
  }
  .effortlessSelfListingSection .descSection p {
    padding-left: 0.625vw;
    font-size: 1.4583333333vw;
    text-align: start;
  }
  .effortlessSelfListingSection .descDownSection {
    border-radius: 2.0833333333vw;
    padding: 1.0416666667vw;
    height: 8.8541666667vw;
    margin-bottom: 3.6458333333vw;
    width: 36.4583333333vw;
  }
  .effortlessSelfListingSection .descDownSection p {
    font-size: 1.1458333333vw;
    margin: 0;
    width: 100%;
    opacity: 0;
    animation: showText 2s linear forwards;
    animation-delay: 4s;
  }
  @keyframes showText {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .effortlessSelfListingSection .selfListing {
    margin-bottom: 2.0833333333vw;
    position: relative;
  }
  .effortlessSelfListingSection .selfListing .contentBox {
    gap: 0.625vw;
    margin-bottom: 1.0416666667vw;
  }
  .effortlessSelfListingSection .selfListing .contentBox::before {
    height: 1.1458333333vw;
    left: 1.4583333333vw;
    top: 2.9166666667vw;
  }
  .effortlessSelfListingSection .selfListing .contentBox img {
    width: 3.125vw;
  }
  .effortlessSelfListingSection .selfListing .contentBox p {
    font-size: 1.3541666667vw;
  }
  .effortlessSelfListingSection .selfListing .contentBox:last-child:before {
    height: 2.2916666667vw;
  }
  .effortlessSelfListingSection .selfListing::before {
    position: absolute;
    content: " ";
    width: 100%;
    height: 18.75vw;
    bottom: -2.6041666667vw;
    background-color: #fafafa;
    z-index: 2;
    animation: shrink 4s linear forwards;
    animation-delay: 1s;
  }
  @keyframes shrink {
    0% {
      height: 18.75vw;
    }
    100% {
      height: 0;
    }
  }
}