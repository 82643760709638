.fixedSellingPriceBuySection {
  padding: 13.3333333333vw 0;
}
.fixedSellingPriceBuySection p.subTitle {
  font-family: Inter;
  font-size: 5.3333333333vw;
  font-weight: 500;
  margin-bottom: 1.6vw;
  text-align: center;
  color: rgb(76, 41, 181);
}
.fixedSellingPriceBuySection .titleSection {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 12px;
  margin-bottom: 1.6vw;
}
.fixedSellingPriceBuySection .titleSection h2 {
  text-align: center;
  font-family: Inter;
  font-size: 7.4666666667vw;
  font-weight: 700;
  color: rgb(76, 41, 181);
  width: 86.6666666667vw;
}
.fixedSellingPriceBuySection .titleSection img {
  display: none;
  width: auto;
  height: 4.2666666667vw;
}
.fixedSellingPriceBuySection .descSection {
  margin-bottom: 8vw;
}
.fixedSellingPriceBuySection .descSection p {
  text-align: center;
  font-size: 4.8vw;
  font-family: Inter;
  font-weight: 400;
  color: rgb(115, 80, 159);
}
.fixedSellingPriceBuySection .descSection p span {
  font-weight: 700;
}
.fixedSellingPriceBuySection .cardItems {
  margin-top: 10.6666666667vw;
  display: flex;
  flex-direction: column;
  gap: 6.9333333333vw;
}
.fixedSellingPriceBuySection .cardItems .contentBox {
  width: 86.6666666667vw;
  background-color: rgba(137, 69, 236, 0.1);
  border-radius: 4.2666666667vw;
  padding: 5.3333333333vw 3.7333333333vw;
  transition: all 0.3s ease-in-out;
  text-align: left;
  border: 3px solid white;
  cursor: pointer;
  margin: 0 auto;
}
.fixedSellingPriceBuySection .cardItems .contentBox .imageSectiom {
  display: none;
}
.fixedSellingPriceBuySection .cardItems .contentBox h2 {
  font-family: Inter;
  font-size: 3.7333333333vw;
  font-weight: 800;
  line-height: 4.5333333333vw;
  color: rgb(76, 41, 181);
  margin: 1.6vw 0;
}
.fixedSellingPriceBuySection .cardItems .contentBox p {
  font-family: Inter;
  font-size: 3.7333333333vw;
  font-weight: 400;
  line-height: 4.5333333333vw;
  color: rgb(76, 41, 181);
  width: 78.1333333333vw;
  margin: 0;
}
.fixedSellingPriceBuySection .cardItems .contentBox:hover {
  background: white;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 80px rgba(137, 69, 236, 0.2);
  transition: all 0.3s ease-in-out;
}
.fixedSellingPriceBuySection .cardItems .contentBox:hover .imageSectiom {
  background: rgb(255, 0, 121);
  transition: all 0.3s ease-in-out;
}
.fixedSellingPriceBuySection .contentBoxAll .fullImage {
  width: 80vw;
  height: auto;
  margin: 13.3333333333vw auto 0;
}
.fixedSellingPriceBuySection .contentBoxAll .fullImage img {
  width: 100%;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .fixedSellingPriceBuySection {
    padding: 5.9952038369vw 0;
  }
  .fixedSellingPriceBuySection p.subTitle {
    font-size: 2.3980815348vw;
    margin-bottom: 0.7194244604vw;
  }
  .fixedSellingPriceBuySection .titleSection {
    margin-bottom: 0.7194244604vw;
    align-items: center;
    gap: 0;
  }
  .fixedSellingPriceBuySection .titleSection h2 {
    font-size: 4.1966426859vw;
    width: 39.2086330935vw;
  }
  .fixedSellingPriceBuySection .titleSection img {
    display: block;
    width: 4.3165467626vw;
    height: auto;
  }
  .fixedSellingPriceBuySection .descSection {
    margin-bottom: 3.5971223022vw;
  }
  .fixedSellingPriceBuySection .descSection p {
    font-size: 2.6378896882vw;
    width: 60.071942446vw;
    margin: 0 auto;
  }
  .fixedSellingPriceBuySection .contentBoxAll .cardItems {
    margin-top: 4.7961630695vw;
    gap: 1.4388489209vw;
  }
  .fixedSellingPriceBuySection .contentBoxAll .cardItems .contentBox {
    width: 70.0239808153vw;
    border-radius: 1.7985611511vw;
    padding: 2.3980815348vw 2.3980815348vw;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 2.3980815348vw;
    margin: 0 auto;
  }
  .fixedSellingPriceBuySection .contentBoxAll .cardItems .contentBox .imageSectiom {
    display: block;
    width: 8.1534772182vw;
    height: 8.1534772182vw;
    padding: 1.1990407674vw;
    background: rgb(137, 69, 236);
    border-radius: 15px;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fixedSellingPriceBuySection .contentBoxAll .cardItems .contentBox .imageSectiom img {
    width: 3.8369304556vw;
  }
  .fixedSellingPriceBuySection .contentBoxAll .cardItems .contentBox:hover .imageSectiom {
    background: rgb(255, 0, 121);
    transition: all 0.3s ease-in-out;
  }
  .fixedSellingPriceBuySection .contentBoxAll .cardItems .contentBox h2 {
    font-size: 2.1582733813vw;
    line-height: 2.6378896882vw;
    margin: 1.4388489209vw 0;
  }
  .fixedSellingPriceBuySection .contentBoxAll .cardItems .contentBox p {
    font-size: 2.0383693046vw;
    line-height: 2.3980815348vw;
    width: 55.51558753vw;
  }
  .fixedSellingPriceBuySection .contentBoxAll .fullImage {
    width: 59.9520383693vw;
    margin: 5.9952038369vw auto 0;
  }
  .fixedSellingPriceBuySection .contentBoxAll .fullImage img {
    width: 59.9520383693vw;
  }
}
@media (min-width: 1024px) {
  .fixedSellingPriceBuySection {
    padding: 2.6041666667vw 0;
  }
  .fixedSellingPriceBuySection p.subTitle {
    font-size: 1.0416666667vw;
    margin-bottom: 0.3125vw;
    text-align: left;
  }
  .fixedSellingPriceBuySection .titleSection {
    margin-bottom: 0.3125vw;
    justify-content: center;
    align-items: center;
    justify-content: start;
  }
  .fixedSellingPriceBuySection .titleSection h2 {
    font-size: 2.4479166667vw;
    width: 20.8333333333vw;
  }
  .fixedSellingPriceBuySection .titleSection img {
    display: block;
    width: 2.0833333333vw;
    height: auto;
    margin-top: -10px;
  }
  .fixedSellingPriceBuySection .descSection {
    margin-bottom: 1.5625vw;
  }
  .fixedSellingPriceBuySection .descSection p {
    font-size: 1.4583333333vw;
    text-align: start;
  }
  .fixedSellingPriceBuySection .contentBoxAll {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2.0833333333vw;
  }
  .fixedSellingPriceBuySection .contentBoxAll .cardItems {
    width: 50%;
    gap: 0.625vw;
    margin-top: 0vw;
  }
  .fixedSellingPriceBuySection .contentBoxAll .cardItems .contentBox {
    width: 37.5vw;
    border-radius: 0.78125vw;
    padding: 1.0416666667vw 1.0416666667vw;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 1.0416666667vw;
    margin: 0;
  }
  .fixedSellingPriceBuySection .contentBoxAll .cardItems .contentBox .imageSectiom {
    display: block;
    width: 3.5416666667vw;
    height: 3.5416666667vw;
    padding: 0.5208333333vw;
    background: rgb(137, 69, 236);
    border-radius: 15px;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fixedSellingPriceBuySection .contentBoxAll .cardItems .contentBox .imageSectiom img {
    width: 1.6666666667vw;
  }
  .fixedSellingPriceBuySection .contentBoxAll .cardItems .contentBox:hover .imageSectiom {
    background: rgb(255, 0, 121);
    transition: all 0.3s ease-in-out;
  }
  .fixedSellingPriceBuySection .contentBoxAll .cardItems .contentBox h2 {
    font-size: 1.0416666667vw;
    line-height: 1.1458333333vw;
    margin: 0.625vw 0;
  }
  .fixedSellingPriceBuySection .contentBoxAll .cardItems .contentBox p {
    font-size: 0.9375vw;
    line-height: 1.0416666667vw;
    width: 30.2083333333vw;
  }
  .fixedSellingPriceBuySection .contentBoxAll .fullImage {
    width: 50%;
    margin: 0;
    text-align: center;
  }
  .fixedSellingPriceBuySection .contentBoxAll .fullImage img {
    width: 65%;
  }
}