.theExperienceSection {
  padding: 13.3333333333vw 0;
}
.theExperienceSection .sliders .contentBox {
  position: relative;
  margin-bottom: 13.3333333333vw;
  width: 100%;
  margin: 0 auto;
  padding: 0 2px;
  border-radius: 0;
  background: linear-gradient(to top left, rgb(76, 41, 181), rgba(137, 69, 236, 0.5));
  overflow: hidden;
}
.theExperienceSection .sliders .contentBox .imageLeftSlider {
  width: 100%;
  position: absolute;
  z-index: -1;
}
.theExperienceSection .sliders .contentBox .imageLeftSlider img {
  width: 100%;
}
.theExperienceSection .sliders .contentBox .boxShadow {
  border-radius: 10px;
  box-shadow: 0px 4px 60px 6px rgba(137, 69, 236, 0.14);
  border: 3px solid rgb(233, 233, 233);
  height: 195.4666666667vw;
  padding: 8vw 4.2666666667vw;
  color: white;
}
.theExperienceSection .sliders .contentBox .boxShadow .sectionTop,
.theExperienceSection .sliders .contentBox .boxShadow .sectionCenter,
.theExperienceSection .sliders .contentBox .boxShadow .sectionDown {
  margin-bottom: 16vw;
}
.theExperienceSection .sliders .contentBox .boxShadow .sectionTop span,
.theExperienceSection .sliders .contentBox .boxShadow .sectionCenter span,
.theExperienceSection .sliders .contentBox .boxShadow .sectionDown span {
  font-family: Inter;
  font-size: 5.3333333333vw;
  font-weight: 500;
  line-height: 6.4vw;
}
.theExperienceSection .sliders .contentBox .boxShadow .sectionTop h2,
.theExperienceSection .sliders .contentBox .boxShadow .sectionCenter h2,
.theExperienceSection .sliders .contentBox .boxShadow .sectionDown h2 {
  font-family: Inter;
  font-size: 7.4666666667vw;
  font-weight: 700;
  line-height: 9.0666666667vw;
  margin: 2.6666666667vw 0;
}
.theExperienceSection .sliders .contentBox .boxShadow .sectionTop h2 img,
.theExperienceSection .sliders .contentBox .boxShadow .sectionCenter h2 img,
.theExperienceSection .sliders .contentBox .boxShadow .sectionDown h2 img {
  display: none;
}
.theExperienceSection .sliders .contentBox .boxShadow .sectionTop p,
.theExperienceSection .sliders .contentBox .boxShadow .sectionCenter p,
.theExperienceSection .sliders .contentBox .boxShadow .sectionDown p {
  font-family: Inter;
  font-size: 4.8vw;
  font-weight: 400;
  line-height: 5.8666666667vw;
  margin: 0;
}
.theExperienceSection .sliders .contentBox .boxShadow .sectionTop p.upSpan,
.theExperienceSection .sliders .contentBox .boxShadow .sectionCenter p.upSpan,
.theExperienceSection .sliders .contentBox .boxShadow .sectionDown p.upSpan {
  margin: 2.6666666667vw 0;
}
.theExperienceSection .sliders .swiper-pagination-bullet {
  display: none;
}
.theExperienceSection .sliders .swiper-button-prev,
.theExperienceSection .sliders .swiper-button-next {
  display: none;
}
.theExperienceSection .arrowSliders {
  position: relative;
}
.theExperienceSection .arrowSliders .containerBox {
  position: absolute;
  left: 18%;
  transform: translateX(-50%);
  top: -34.1333333333vw;
  display: flex;
  gap: 5.3333333333vw;
  z-index: 1;
}
.theExperienceSection .arrowSliders .containerBox .swiper-button-prevStyle,
.theExperienceSection .arrowSliders .containerBox .swiper-button-nextStyle {
  cursor: pointer;
  width: 20px;
  height: 20px;
  background: rgb(224, 224, 224);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  padding: 16px;
  border: 2px solid rgb(224, 224, 224);
}
.theExperienceSection .arrowSliders .containerBox .swiper-button-prevStyle:hover,
.theExperienceSection .arrowSliders .containerBox .swiper-button-nextStyle:hover {
  background: white;
}
.theExperienceSection .arrowSliders .containerBox .swiper-button-prevStyle {
  transform: rotate(180deg);
}

@media (min-width: 768px) and (max-width: 1023px) {
  .theExperienceSection {
    padding: 11.9904076739vw 0;
  }
  .theExperienceSection .sliders .contentBox {
    margin-bottom: 5.9952038369vw;
    background: linear-gradient(to top left, rgb(76, 41, 181), rgba(137, 69, 236, 0.5)), url("../../assets/TheExperienceSection/slider1Tab.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .theExperienceSection .sliders .contentBox .boxShadow {
    height: 71.9424460432vw;
    padding: 5.9952038369vw 5.9952038369vw;
  }
  .theExperienceSection .sliders .contentBox .boxShadow .sectionTop,
  .theExperienceSection .sliders .contentBox .boxShadow .sectionCenter,
  .theExperienceSection .sliders .contentBox .boxShadow .sectionDown {
    margin-bottom: 7.1942446043vw;
  }
  .theExperienceSection .sliders .contentBox .boxShadow .sectionTop span,
  .theExperienceSection .sliders .contentBox .boxShadow .sectionCenter span,
  .theExperienceSection .sliders .contentBox .boxShadow .sectionDown span {
    font-size: 2.3980815348vw;
    line-height: 2.8776978417vw;
  }
  .theExperienceSection .sliders .contentBox .boxShadow .sectionTop h2,
  .theExperienceSection .sliders .contentBox .boxShadow .sectionCenter h2,
  .theExperienceSection .sliders .contentBox .boxShadow .sectionDown h2 {
    font-size: 3.3573141487vw;
    line-height: 4.0767386091vw;
    margin: 1.1990407674vw 0;
    display: flex;
    gap: 2.3980815348vw;
  }
  .theExperienceSection .sliders .contentBox .boxShadow .sectionTop h2 img,
  .theExperienceSection .sliders .contentBox .boxShadow .sectionCenter h2 img,
  .theExperienceSection .sliders .contentBox .boxShadow .sectionDown h2 img {
    display: block;
    width: 3.5971223022vw;
    height: auto;
  }
  .theExperienceSection .sliders .contentBox .boxShadow .sectionTop p,
  .theExperienceSection .sliders .contentBox .boxShadow .sectionCenter p,
  .theExperienceSection .sliders .contentBox .boxShadow .sectionDown p {
    font-size: 2.1582733813vw;
    line-height: 2.6378896882vw;
  }
  .theExperienceSection .sliders .contentBox .boxShadow .sectionTop p.upSpan,
  .theExperienceSection .sliders .contentBox .boxShadow .sectionCenter p.upSpan,
  .theExperienceSection .sliders .contentBox .boxShadow .sectionDown p.upSpan {
    margin: 1.1990407674vw 0;
  }
  .theExperienceSection .arrowSliders .containerBox {
    left: 12%;
    top: -17.9856115108vw;
    gap: 2.3980815348vw;
  }
}
@media (min-width: 1024px) {
  .theExperienceSection {
    padding: 2.6041666667vw 0;
    position: relative;
  }
  .theExperienceSection .sliders {
    width: 82%;
    margin: 0 auto;
  }
  .theExperienceSection .sliders .contentBox {
    margin-bottom: 2.6041666667vw;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    gap: 0.625vw;
    background: unset;
  }
  .theExperienceSection .sliders .contentBox .imageLeftSlider {
    width: auto;
    position: relative;
  }
  .theExperienceSection .sliders .contentBox .imageLeftSlider img {
    width: auto;
    height: 44.6354166667vw;
    background: linear-gradient(to top left, rgba(137, 69, 236, 0.4), rgba(137, 69, 236, 0.07), rgba(104, 0, 255, 0.01));
  }
  .theExperienceSection .sliders .contentBox .imageLeftSlider:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top left, rgba(137, 69, 236, 0.4), rgba(137, 69, 236, 0.07), rgba(104, 0, 255, 0.01));
    z-index: 1;
    pointer-events: none;
  }
  .theExperienceSection .sliders .contentBox .boxShadow {
    height: 44.6354166667vw;
    padding: 2.6041666667vw 2.6041666667vw;
    background: linear-gradient(to top left, rgb(76, 41, 181), rgba(137, 69, 236, 0.5));
    border-width: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .theExperienceSection .sliders .contentBox .boxShadow .sectionTop,
  .theExperienceSection .sliders .contentBox .boxShadow .sectionCenter,
  .theExperienceSection .sliders .contentBox .boxShadow .sectionDown {
    margin-bottom: 3.125vw;
  }
  .theExperienceSection .sliders .contentBox .boxShadow .sectionTop span,
  .theExperienceSection .sliders .contentBox .boxShadow .sectionCenter span,
  .theExperienceSection .sliders .contentBox .boxShadow .sectionDown span {
    font-size: 1.0416666667vw;
    line-height: 1.25vw;
  }
  .theExperienceSection .sliders .contentBox .boxShadow .sectionTop h2,
  .theExperienceSection .sliders .contentBox .boxShadow .sectionCenter h2,
  .theExperienceSection .sliders .contentBox .boxShadow .sectionDown h2 {
    font-size: 2.4479166667vw;
    line-height: 2.9166666667vw;
    margin: 0.8333333333vw 0;
    display: flex;
    gap: 0.8333333333vw;
  }
  .theExperienceSection .sliders .contentBox .boxShadow .sectionTop h2 img,
  .theExperienceSection .sliders .contentBox .boxShadow .sectionCenter h2 img,
  .theExperienceSection .sliders .contentBox .boxShadow .sectionDown h2 img {
    display: block;
    width: 1.875vw;
    height: auto;
  }
  .theExperienceSection .sliders .contentBox .boxShadow .sectionTop p,
  .theExperienceSection .sliders .contentBox .boxShadow .sectionCenter p,
  .theExperienceSection .sliders .contentBox .boxShadow .sectionDown p {
    font-size: 1.1458333333vw;
    line-height: 1.3541666667vw;
  }
  .theExperienceSection .sliders .contentBox .boxShadow .sectionTop p.upSpan,
  .theExperienceSection .sliders .contentBox .boxShadow .sectionCenter p.upSpan,
  .theExperienceSection .sliders .contentBox .boxShadow .sectionDown p.upSpan {
    margin: 0.5208333333vw 0;
  }
  .theExperienceSection .arrowSliders .containerBox {
    left: 21%;
    top: -7.8125vw;
    gap: 1.0416666667vw;
  }
}