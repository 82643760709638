.termsAndConditionsPage {
  padding: 26.6666666667vw 0 10.6666666667vw;
}
.termsAndConditionsPage .paddingTopBottom {
  padding-bottom: 10.6666666667vw;
}
.termsAndConditionsPage h1 {
  font-size: 6.9333333333vw;
  color: #333;
  text-align: center;
  margin-bottom: 5.8666666667vw;
}
.termsAndConditionsPage h2 {
  font-size: 5.8666666667vw;
  color: #333;
  margin-top: 5.8666666667vw;
  margin-bottom: 3.2vw;
}
.termsAndConditionsPage p {
  margin-bottom: 3.2vw;
  text-align: justify;
}
.termsAndConditionsPage ul {
  margin-left: 5.8666666667vw;
  list-style-type: disc;
}
.termsAndConditionsPage a.getGackToTheRegistrationPage {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(154, 97, 238);
  border: 2px solid #ddd;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: 0 auto;
  color: white;
  text-decoration: none;
  border-radius: 12px;
  font-family: Inter;
  font-weight: 400;
  margin-top: 13.3333333333vw;
  padding: 0 5.3333333333vw;
  height: 13.3333333333vw;
  font-size: 4.2666666667vw;
  transition: all 0.3s ease-in-out;
}
.termsAndConditionsPage a.getGackToTheRegistrationPage:hover {
  color: rgb(154, 97, 238);
  background-color: white;
  box-shadow: 0px 0px 100px -22px rgb(66, 68, 90);
  transition: all 0.3s ease-in-out;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .termsAndConditionsPage {
    padding: 11.9904076739vw 0 4.7961630695vw;
  }
  .termsAndConditionsPage .paddingTopBottom {
    padding-bottom: 4.7961630695vw;
  }
  .termsAndConditionsPage h1 {
    font-size: 3.1175059952vw;
    margin-bottom: 2.6378896882vw;
  }
  .termsAndConditionsPage h2 {
    font-size: 2.6378896882vw;
    margin-top: 2.6378896882vw;
    margin-bottom: 1.4388489209vw;
  }
  .termsAndConditionsPage p {
    margin-bottom: 1.4388489209vw;
  }
  .termsAndConditionsPage ul {
    margin-left: 2.6378896882vw;
  }
  .termsAndConditionsPage a.getGackToTheRegistrationPage {
    margin-top: 5.9952038369vw;
    padding: 0 2.3980815348vw;
    height: 5.9952038369vw;
    font-size: 1.9184652278vw;
  }
}
@media (min-width: 1024px) {
  .termsAndConditionsPage {
    padding: 8.8541666667vw 0 2.0833333333vw;
  }
  .termsAndConditionsPage .paddingTopBottom {
    padding-top: 2.0833333333vw;
    padding-bottom: 2.0833333333vw;
  }
  .termsAndConditionsPage h1 {
    font-size: 2.1875vw;
    margin-bottom: 1.5625vw;
  }
  .termsAndConditionsPage h2 {
    font-size: 1.3541666667vw;
    margin-top: 1.5625vw;
    margin-bottom: 0.625vw;
  }
  .termsAndConditionsPage p {
    margin-bottom: 1.0416666667vw;
  }
  .termsAndConditionsPage ul {
    margin-left: 1.1458333333vw;
  }
  .termsAndConditionsPage a.getGackToTheRegistrationPage {
    margin-top: 2.6041666667vw;
    padding: 0 1.5625vw;
    height: 3.6458333333vw;
    font-size: 1.1458333333vw;
  }
}