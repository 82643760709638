.buyingAndSellingSection.buyingAndSelling .titleSection {
  padding: 8vw 0;
  text-align: center;
}
.buyingAndSellingSection.buyingAndSelling .titleSection h2 {
  font-family: Inter;
  font-size: 9.3333333333vw;
  font-weight: 600;
  line-height: 12.8vw;
  color: rgb(76, 41, 181);
  margin: 0;
}
.buyingAndSellingSection.buyingAndSelling .titleSection p {
  font-family: Inter;
  font-size: 4vw;
  font-weight: 400;
  line-height: 12.8vw;
  color: rgb(158, 157, 157);
  margin: 0;
}
.buyingAndSellingSection.buyingAndSelling .barStepMobile {
  padding: 13.3333333333vw 0;
  position: relative;
}
.buyingAndSellingSection.buyingAndSelling .barStepMobile::before {
  position: absolute;
  content: " ";
  width: 90%;
  height: 1.0666666667vw;
  background-color: rgb(224, 224, 224);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.buyingAndSellingSection.buyingAndSelling .barStepMobile ul {
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 86.1333333333vw;
  margin: 0 auto;
}
.buyingAndSellingSection.buyingAndSelling .barStepMobile ul li {
  position: relative;
  list-style-type: none;
  width: 4.5333333333vw;
  height: 4.5333333333vw;
  background-color: rgb(224, 224, 224);
  border-radius: 17.5px;
}
.buyingAndSellingSection.buyingAndSelling .barStepMobile ul li p {
  display: none;
}
.buyingAndSellingSection.buyingAndSelling .barStepMobile ul li.aciveStep {
  background-color: rgb(76, 41, 181);
}
.buyingAndSellingSection.buyingAndSelling .barStepMobile ul li.aciveStep::before {
  position: absolute;
  content: " ";
  background-image: url("../../assets//successBar.svg");
  background-repeat: no-repeat;
  width: 1.6vw;
  height: 1.6vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.buyingAndSellingSection.buyingAndSelling .barStepMobile ul li.aciveStep.activeSelect p {
  position: absolute;
  top: 8vw;
  margin: 0;
  display: block;
  font-family: Inter;
  font-size: 2.6666666667vw;
  line-height: 3.2vw;
  color: rgb(76, 41, 181);
  font-weight: 600;
  width: 26.6666666667vw;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
.buyingAndSellingSection.buyingAndSelling .desktopSection {
  display: none;
}
.buyingAndSellingSection.buyingAndSelling .setionFormBox {
  width: 92vw;
  margin: 0 auto;
}
.buyingAndSellingSection.buyingAndSelling .error {
  font-size: 3.2vw;
  font-family: "Inter";
  color: #ef5350;
}
.buyingAndSellingSection.buyingAndSelling p.labelSelectMultiOptions {
  font-family: Inter;
  font-size: 3.2vw;
  font-weight: 300;
  color: gray;
  margin: 0;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .buyingAndSellingSection.buyingAndSelling .titleSection {
    padding: 3.5971223022vw 0;
    text-align: center;
  }
  .buyingAndSellingSection.buyingAndSelling .titleSection h2 {
    font-size: 4.1966426859vw;
    line-height: 5.7553956835vw;
  }
  .buyingAndSellingSection.buyingAndSelling .titleSection p {
    font-size: 1.7985611511vw;
    line-height: 5.7553956835vw;
  }
  .buyingAndSellingSection.buyingAndSelling .barStepMobile {
    padding: 5.9952038369vw 0;
  }
  .buyingAndSellingSection.buyingAndSelling .barStepMobile::before {
    width: 90%;
    height: 0.479616307vw;
  }
  .buyingAndSellingSection.buyingAndSelling .barStepMobile ul {
    width: 74.7002398082vw;
  }
  .buyingAndSellingSection.buyingAndSelling .barStepMobile ul li {
    width: 2.0383693046vw;
    height: 2.0383693046vw;
  }
  .buyingAndSellingSection.buyingAndSelling .barStepMobile ul li p {
    position: absolute;
    top: 3.5971223022vw;
    margin: 0;
    display: block;
    font-family: Inter;
    font-size: 1.1990407674vw;
    line-height: 1.4388489209vw;
    color: rgb(224, 224, 224);
    font-weight: 600;
    width: 23.9808153477vw;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }
  .buyingAndSellingSection.buyingAndSelling .barStepMobile ul li.aciveStep::before {
    width: 0.7194244604vw;
    height: 0.7194244604vw;
  }
  .buyingAndSellingSection.buyingAndSelling .barStepMobile ul li.aciveStep.activeSelect p {
    top: 3.5971223022vw;
    font-size: 1.1990407674vw;
    line-height: 1.4388489209vw;
    width: 23.9808153477vw;
  }
  .buyingAndSellingSection.buyingAndSelling .setionFormBox {
    width: 83.932853717vw;
    margin: 0 auto;
  }
  .buyingAndSellingSection.buyingAndSelling .error {
    font-size: 1.4388489209vw;
  }
  .buyingAndSellingSection.buyingAndSelling p.labelSelectMultiOptions {
    font-size: 1.6786570743vw;
  }
}
@media (min-width: 1024px) {
  .buyingAndSellingSection.buyingAndSelling .titleSection {
    width: 86.875vw;
    padding: 3.125vw 0;
    text-align: center;
  }
  .buyingAndSellingSection.buyingAndSelling .titleSection h2 {
    font-size: 3.28125vw;
    line-height: 2.5vw;
    margin-bottom: 1.0416666667vw;
  }
  .buyingAndSellingSection.buyingAndSelling .titleSection p {
    font-size: 1.4583333333vw;
    line-height: 2.5vw;
  }
  .buyingAndSellingSection.buyingAndSelling .mobileSection {
    display: none;
  }
  .buyingAndSellingSection.buyingAndSelling .desktopSection {
    display: block;
  }
  .buyingAndSellingSection.buyingAndSelling .barStepMobile {
    padding: 2.6041666667vw 0;
    width: 86.875vw;
  }
  .buyingAndSellingSection.buyingAndSelling .barStepMobile::before {
    width: 76.71875vw;
    height: 0.2083333333vw;
  }
  .buyingAndSellingSection.buyingAndSelling .barStepMobile ul {
    margin: 0;
  }
  .buyingAndSellingSection.buyingAndSelling .barStepMobile ul li {
    width: 1.8229166667vw;
    height: 1.8229166667vw;
  }
  .buyingAndSellingSection.buyingAndSelling .barStepMobile ul li p {
    position: absolute;
    top: 3.125vw;
    margin: 0;
    display: block;
    font-family: Inter;
    font-size: 1.1458333333vw;
    line-height: 0.625vw;
    color: rgb(224, 224, 224);
    font-weight: 600;
    width: 12.5vw;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }
  .buyingAndSellingSection.buyingAndSelling .barStepMobile ul li.aciveStep::before {
    width: 15px;
    height: 10px;
    background-image: url("../../assets/activeLinke.svg");
  }
  .buyingAndSellingSection.buyingAndSelling .barStepMobile ul li.aciveStep.activeSelect p {
    top: 3.125vw;
    font-size: 1.1458333333vw;
    line-height: 0.625vw;
    width: 13.0208333333vw;
  }
  .buyingAndSellingSection.buyingAndSelling .barStepMobile ul li.smallRadius {
    width: 1.09375vw;
    height: 1.09375vw;
    position: absolute;
  }
  .buyingAndSellingSection.buyingAndSelling .barStepMobile ul li.smallRadius.one {
    left: 16.6666666667vw;
  }
  .buyingAndSellingSection.buyingAndSelling .barStepMobile ul li.smallRadius.two {
    right: 33.8541666667vw;
  }
  .buyingAndSellingSection.buyingAndSelling .barStepMobile ul li.aciveStep.activeSmallRadius.aciveStep::before {
    position: absolute;
    content: " ";
    width: 1.3020833333vw;
    height: 1.3020833333vw;
    background-image: url("../../assets/activeLinke.svg");
    background-color: rgb(76, 41, 181);
    background-repeat: no-repeat;
    background-size: 0.625vw 0.625vw;
    border-radius: 100px;
    background-position: center;
  }
  .buyingAndSellingSection.buyingAndSelling .setionFormBox {
    width: 86.875vw;
    margin: 0;
    display: flex;
    justify-content: center;
  }
  .buyingAndSellingSection.buyingAndSelling .setionFormBox .btnSection {
    margin-bottom: 3.125vw;
  }
  .buyingAndSellingSection.buyingAndSelling .error {
    font-size: 0.7291666667vw;
  }
  .buyingAndSellingSection.buyingAndSelling p.labelSelectMultiOptions {
    font-size: 0.7291666667vw;
  }
}