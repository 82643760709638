header.header {
  margin: 5.3333333333vw 0 0 0;
  box-shadow: 0 4px 55px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 12px;
  background-color: white;
  position: absolute;
  width: 93.6vw;
  left: 50%;
  transform: translateX(-50%);
}
header.header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle, rgba(76, 41, 181, 0.2) 0%, rgba(255, 0, 0, 0) 250%);
  filter: blur(10px);
  z-index: -1;
}
header.header .mobileView {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8vw;
}
header.header .mobileView img.barMenue {
  cursor: pointer;
  width: 4.2666666667vw;
  height: auto;
}
header.header .mobileView img.LogoHeader {
  width: 8.5333333333vw;
  height: auto;
}
header.header .showNav {
  width: 100%;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.3s ease-in-out, transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
header.header .showNav.showNavActive {
  max-height: 53.3333333333vw;
  overflow: unset;
  opacity: 1;
}
header.header .showNav ul {
  padding: 0 8vw;
}
header.header .showNav ul li {
  list-style-type: none;
}
header.header .showNav ul li a {
  text-decoration: none;
  font-family: Inter;
  font-size: 4.2666666667vw;
  font-weight: 400;
  color: rgb(76, 41, 181);
}
header.header .showNav ul li a.activeLink {
  color: rgb(255, 0, 121);
  position: relative;
}
header.header .showNav ul li a.activeLink::after {
  position: absolute;
  content: " ";
  background-image: url("../../assets/arrowActiveLink.svg");
  background-repeat: no-repeat;
  background-size: 1.8666666667vw 3.2vw;
  width: 1.8666666667vw;
  height: 3.2vw;
  top: 28%;
  right: -4.2666666667vw;
}
header.header .desktopView {
  display: none;
}

@media (min-width: 768px) and (max-width: 1023px) {
  header.header {
    margin: 2.3980815348vw 0;
    width: 92.0863309353vw;
  }
  header.header .mobileView {
    padding: 0 3.5971223022vw;
  }
  header.header .mobileView img.barMenue {
    width: 1.9184652278vw;
  }
  header.header .mobileView img.LogoHeader {
    width: 3.8369304556vw;
  }
  header.header .showNav.showNavActive {
    max-height: 23.9808153477vw;
  }
  header.header .showNav ul {
    padding: 0 3.5971223022vw;
  }
  header.header .showNav ul li a {
    font-size: 1.9184652278vw;
  }
  header.header .showNav ul li a.activeLink::after {
    background-size: 0.8393285372vw 1.4388489209vw;
    width: 0.8393285372vw;
    height: 1.4388489209vw;
    right: -1.9184652278vw;
  }
}
@media (min-width: 1024px) {
  header.header {
    margin: 2.0833333333vw 0;
    width: 83.3333333333vw;
  }
  header.header::before {
    background: radial-gradient(circle, rgba(76, 41, 181, 0.2) 0%, rgba(255, 0, 0, 0) 120%);
  }
  header.header .mobileView {
    display: none;
  }
  header.header .showNav {
    display: none;
  }
  header.header .desktopView {
    display: block;
  }
  header.header .desktopView ul {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0;
  }
  header.header .desktopView ul li {
    list-style-type: none;
  }
  header.header .desktopView ul li a {
    text-decoration: none;
    font-family: Inter;
    font-size: 1.09375vw;
    font-weight: 600;
    color: rgb(76, 41, 181);
  }
  header.header .desktopView ul li a img {
    width: 3.0729166667vw;
    height: auto;
  }
  header.header .desktopView ul li a.activeLink {
    color: rgb(255, 0, 121);
  }
}