.accessToBuyersAgentsSection {
  padding: 13.3333333333vw 0;
}
.accessToBuyersAgentsSection p.subTitle {
  font-family: Inter;
  font-size: 5.3333333333vw;
  font-weight: 500;
  margin-bottom: 1.6vw;
  text-align: center;
  color: rgb(76, 41, 181);
}
.accessToBuyersAgentsSection .titleSection {
  text-align: center;
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 12px;
  margin-bottom: 1.6vw;
}
.accessToBuyersAgentsSection .titleSection h2 {
  font-family: Inter;
  font-size: 7.4666666667vw;
  font-weight: 700;
  color: rgb(76, 41, 181);
}
.accessToBuyersAgentsSection .titleSection img {
  display: none;
}
.accessToBuyersAgentsSection .descSection {
  margin-bottom: 8vw;
}
.accessToBuyersAgentsSection .descSection p {
  text-align: center;
  font-size: 4.8vw;
  font-family: Inter;
  font-weight: 500;
  color: rgb(115, 80, 159);
}
.accessToBuyersAgentsSection .descSection p span {
  font-weight: 700;
}
.accessToBuyersAgentsSection .twoSection .sectionLeft h2 {
  text-align: center;
  font-family: Inter;
  font-size: 7.4666666667vw;
  color: rgb(76, 41, 181);
  font-weight: 700;
  width: 64vw;
  margin: 0 auto;
  margin-bottom: 5.3333333333vw;
}
.accessToBuyersAgentsSection .twoSection .sectionLeft h2 span {
  font-size: 7.4666666667vw;
  color: rgb(255, 0, 121);
}
.accessToBuyersAgentsSection .twoSection .sectionLeft img {
  width: 100%;
}
.accessToBuyersAgentsSection .twoSection .sectionLeft .imageHoverSlash {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.accessToBuyersAgentsSection .twoSection .sectionLeft .imageHoverSlash::before {
  position: absolute;
  content: " ";
  background-image: url("../../assets/slashImage.svg");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 2;
  transition: all 0.3s ease-in-out;
}
.accessToBuyersAgentsSection .twoSection .sectionLeft .imageHoverSlash:hover::before {
  transform: translateX(18%);
  transition: all 0.3s ease-in-out;
}
.accessToBuyersAgentsSection .twoSection .sectionRight h2 {
  text-align: center;
  font-family: Inter;
  font-size: 7.4666666667vw;
  color: rgb(255, 0, 121);
  font-weight: 700;
  width: 66.6666666667vw;
  margin: 0 auto;
  margin-top: 10.6666666667vw;
}
.accessToBuyersAgentsSection .twoSection .sectionRight p {
  text-align: center;
  font-weight: 400;
  font-size: 4.8vw;
  color: rgb(76, 41, 181);
  margin-bottom: 16vw;
}
.accessToBuyersAgentsSection .twoSection .sectionRight .cardItems {
  width: 66.6666666667vw;
  margin: 0 auto;
}
.accessToBuyersAgentsSection .twoSection .sectionRight .cardItems .contenCard {
  cursor: pointer;
  background: linear-gradient(to top left, rgba(137, 69, 236, 0.2), rgba(137, 69, 236, 0));
  border-radius: 10.6666666667vw;
  padding: 5.3333333333vw;
  height: 45.3333333333vw;
  width: 67.7333333333vw;
  margin-bottom: 18.6666666667vw;
  transition: all 0.3s ease-in-out;
  border: 3px solid white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.accessToBuyersAgentsSection .twoSection .sectionRight .cardItems .contenCard h3 {
  font-family: Inter;
  font-size: 4.8vw;
  font-weight: 800;
  color: rgb(76, 41, 181);
}
.accessToBuyersAgentsSection .twoSection .sectionRight .cardItems .contenCard p {
  font-family: Inter;
  font-size: 4.2666666667vw;
  font-weight: 400;
  color: rgb(76, 41, 181);
  text-align: unset;
}
.accessToBuyersAgentsSection .twoSection .sectionRight .cardItems .contenCard .imageHover {
  position: absolute;
  top: -6.6666666667vw;
  left: 6.4vw;
  margin-bottom: -8vw;
  width: 18.1333333333vw;
  height: 18.1333333333vw;
  border: 2px solid white;
  border-radius: 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(137, 69, 236);
  transition: all 0.3s ease-in-out;
}
.accessToBuyersAgentsSection .twoSection .sectionRight .cardItems .contenCard .imageHover img {
  width: 8.5333333333vw;
  height: auto;
}
.accessToBuyersAgentsSection .twoSection .sectionRight .cardItems .contenCard:hover {
  background: white;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 80px rgba(137, 69, 236, 0.2);
  transition: all 0.3s ease-in-out;
}
.accessToBuyersAgentsSection .twoSection .sectionRight .cardItems .contenCard:hover .imageHover {
  background: rgb(255, 0, 121);
  transition: all 0.3s ease-in-out;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .accessToBuyersAgentsSection {
    padding: 5.9952038369vw 0;
  }
  .accessToBuyersAgentsSection p.subTitle {
    font-size: 2.3980815348vw;
    margin-bottom: 0.7194244604vw;
  }
  .accessToBuyersAgentsSection .titleSection {
    margin-bottom: 0.7194244604vw;
  }
  .accessToBuyersAgentsSection .titleSection h2 {
    font-size: 4.1966426859vw;
  }
  .accessToBuyersAgentsSection .titleSection img {
    display: block;
    height: 3.5971223022vw;
  }
  .accessToBuyersAgentsSection .descSection {
    margin-bottom: 3.5971223022vw;
  }
  .accessToBuyersAgentsSection .descSection p {
    font-size: 2.6378896882vw;
  }
  .accessToBuyersAgentsSection .twoSection .sectionLeft h2 {
    font-size: 3.3573141487vw;
  }
  .accessToBuyersAgentsSection .twoSection .sectionLeft h2 span {
    font-size: 3.7170263789vw;
  }
  .accessToBuyersAgentsSection .twoSection .sectionRight {
    margin-top: 5.51558753vw;
  }
  .accessToBuyersAgentsSection .twoSection .sectionRight h2 {
    font-size: 3.8369304556vw;
  }
  .accessToBuyersAgentsSection .twoSection .sectionRight p {
    font-size: 2.8776978417vw;
    margin-bottom: 7.1942446043vw;
  }
  .accessToBuyersAgentsSection .twoSection .sectionRight .cardItems {
    display: flex;
    justify-content: space-around;
  }
  .accessToBuyersAgentsSection .twoSection .sectionRight .cardItems .contenCard {
    border-radius: 4.7961630695vw;
    padding: 2.3980815348vw;
    height: 20.3836930456vw;
    margin-bottom: 8.3932853717vw;
    width: 47.9616306954vw;
  }
  .accessToBuyersAgentsSection .twoSection .sectionRight .cardItems .contenCard h3 {
    font-size: 2.1582733813vw;
  }
  .accessToBuyersAgentsSection .twoSection .sectionRight .cardItems .contenCard p {
    font-size: 1.9184652278vw;
  }
  .accessToBuyersAgentsSection .twoSection .sectionRight .cardItems .contenCard .imageHover {
    top: -5.2757793765vw;
    margin-bottom: -3.5971223022vw;
    width: 8.1534772182vw;
    height: 8.1534772182vw;
    border-radius: 1.7985611511vw;
  }
  .accessToBuyersAgentsSection .twoSection .sectionRight .cardItems .contenCard .imageHover img {
    width: 3.8369304556vw;
  }
}
@media (min-width: 1024px) {
  .accessToBuyersAgentsSection {
    padding: 2.6041666667vw 0;
  }
  .accessToBuyersAgentsSection p.subTitle {
    font-size: 1.0416666667vw;
    margin-bottom: 0.3125vw;
    text-align: start;
  }
  .accessToBuyersAgentsSection .titleSection {
    margin-bottom: 0.3125vw;
    justify-content: start;
    align-items: center;
  }
  .accessToBuyersAgentsSection .titleSection h2 {
    font-size: 2.4479166667vw;
  }
  .accessToBuyersAgentsSection .titleSection img {
    width: 2.0833333333vw;
    height: auto;
    margin-top: -10px;
    display: block;
  }
  .accessToBuyersAgentsSection .descSection {
    margin-bottom: 1.5625vw;
  }
  .accessToBuyersAgentsSection .descSection p {
    font-size: 1.4583333333vw;
    text-align: start;
  }
  .accessToBuyersAgentsSection .twoSection {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
  .accessToBuyersAgentsSection .twoSection .sectionLeft {
    width: 50%;
  }
  .accessToBuyersAgentsSection .twoSection .sectionLeft h2 {
    font-size: 1.4583333333vw;
    width: 26.0416666667vw;
    margin-bottom: 1.5625vw;
  }
  .accessToBuyersAgentsSection .twoSection .sectionLeft h2 span {
    font-size: 2.5vw;
  }
  .accessToBuyersAgentsSection .twoSection .sectionRight h2 {
    font-size: 1.6666666667vw;
    text-align: left;
    width: 31.25vw;
    margin: 0 0 16px 0;
  }
  .accessToBuyersAgentsSection .twoSection .sectionRight p {
    font-size: 1.25vw;
    margin-bottom: 6.25vw;
    text-align: left;
  }
  .accessToBuyersAgentsSection .twoSection .sectionRight .cardItems {
    display: flex;
    gap: 1.0416666667vw;
    width: 32.8125vw;
    margin: 0;
  }
  .accessToBuyersAgentsSection .twoSection .sectionRight .cardItems .contenCard {
    border-radius: 2.0833333333vw;
    padding: 1.0416666667vw 2.0833333333vw;
    height: 8.8541666667vw;
    margin-bottom: 0vw;
  }
  .accessToBuyersAgentsSection .twoSection .sectionRight .cardItems .contenCard h3 {
    font-size: 0.9375vw;
  }
  .accessToBuyersAgentsSection .twoSection .sectionRight .cardItems .contenCard p {
    font-size: 0.8333333333vw;
  }
  .accessToBuyersAgentsSection .twoSection .sectionRight .cardItems .contenCard .imageHover {
    top: -0.9375vw;
    left: 1.0416666667vw;
    margin-bottom: -1.5625vw;
    width: 3.5416666667vw;
    height: 3.5416666667vw;
    border-radius: 0.78125vw;
  }
  .accessToBuyersAgentsSection .twoSection .sectionRight .cardItems .contenCard .imageHover img {
    width: 1.6666666667vw;
  }
}