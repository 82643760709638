.authenticListingsSection {
  padding: 13.3333333333vw 0;
}
.authenticListingsSection p.subTitle {
  font-family: Inter;
  font-size: 5.3333333333vw;
  font-weight: 500;
  margin-bottom: 1.6vw;
  text-align: center;
  color: rgb(76, 41, 181);
}
.authenticListingsSection .titleSection {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 12px;
  margin-bottom: 1.6vw;
}
.authenticListingsSection .titleSection h2 {
  text-align: center;
  font-family: Inter;
  font-size: 7.4666666667vw;
  font-weight: 700;
  color: rgb(76, 41, 181);
  width: 86.6666666667vw;
}
.authenticListingsSection .titleSection img {
  display: none;
  width: auto;
  height: 4.2666666667vw;
}
.authenticListingsSection .descSection {
  margin-bottom: 8vw;
}
.authenticListingsSection .descSection p {
  text-align: center;
  font-size: 4.8vw;
  font-family: Inter;
  font-weight: 400;
  color: rgb(115, 80, 159);
}
.authenticListingsSection .descSection p span {
  font-weight: 700;
}
.authenticListingsSection .cardItems {
  margin-top: 10.6666666667vw;
  display: flex;
  flex-direction: column;
  gap: 6.9333333333vw;
}
.authenticListingsSection .cardItems .contentBox {
  width: 86.6666666667vw;
  background-color: rgba(137, 69, 236, 0.1);
  border-radius: 4.2666666667vw;
  padding: 5.3333333333vw 3.7333333333vw;
  transition: all 0.3s ease-in-out;
  text-align: left;
  margin: 0 auto;
  border: 3px solid white;
  cursor: pointer;
  gap: 3.2vw;
  display: flex;
  align-items: center;
  opacity: 0;
  transform: translateY(20px);
}
.authenticListingsSection .cardItems .contentBox.animationShowSlow:nth-child(1) {
  animation: fadeInUp 0.8s ease-out forwards;
  animation-delay: 1s;
}
.authenticListingsSection .cardItems .contentBox.animationShowSlow:nth-child(2) {
  animation: fadeInUp 0.8s ease-out forwards;
  animation-delay: 2s;
}
.authenticListingsSection .cardItems .contentBox.animationShowSlow:nth-child(3) {
  animation: fadeInUp 0.8s ease-out 3s forwards, fadeInOut 0.8s ease-in-out 5s forwards;
}
.authenticListingsSection .cardItems .contentBox.animationShowSlow:nth-child(4) {
  animation: fadeInUp 0.8s ease-out forwards;
  animation-delay: 4s;
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(0);
    display: none;
  }
}
.authenticListingsSection .cardItems .contentBox .imageSectiom {
  width: 15.4666666667vw;
  height: 15.4666666667vw;
  background: rgb(137, 69, 236);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
}
.authenticListingsSection .cardItems .contentBox.closeSection .imageSectiom {
  background: rgb(255, 0, 121);
}
.authenticListingsSection .cardItems .contentBox.closeSection .imageSectiom img {
  width: 12.2666666667vw;
}
.authenticListingsSection .cardItems .contentBox h2 {
  font-family: Inter;
  font-size: 3.7333333333vw;
  font-weight: 800;
  line-height: 4.5333333333vw;
  color: rgb(76, 41, 181);
  margin: 1.6vw 0;
}
.authenticListingsSection .cardItems .contentBox p {
  font-family: Inter;
  font-size: 3.7333333333vw;
  font-weight: 400;
  line-height: 4.5333333333vw;
  color: rgb(76, 41, 181);
  width: 78.1333333333vw;
  margin: 0;
}
.authenticListingsSection .fullImage {
  width: 100%;
  height: 28vw;
  margin-bottom: 13.3333333333vw;
}
.authenticListingsSection .fullImage img {
  width: 100%;
  height: auto;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .authenticListingsSection {
    padding: 5.9952038369vw 0;
  }
  .authenticListingsSection p.subTitle {
    font-size: 2.3980815348vw;
    margin-bottom: 0.7194244604vw;
  }
  .authenticListingsSection .titleSection {
    margin-bottom: 0.7194244604vw;
    align-items: center;
    gap: 0;
  }
  .authenticListingsSection .titleSection h2 {
    font-size: 4.1966426859vw;
    width: 50.1199040767vw;
  }
  .authenticListingsSection .titleSection img {
    display: block;
    width: 4.3165467626vw;
    height: auto;
  }
  .authenticListingsSection .descSection {
    margin-bottom: 7.1942446043vw;
  }
  .authenticListingsSection .descSection p {
    font-size: 2.6378896882vw;
    width: 60.071942446vw;
    margin: 0 auto;
  }
  .authenticListingsSection .cardItems {
    margin-top: 14.3884892086vw;
    gap: 1.4388489209vw;
  }
  .authenticListingsSection .cardItems .contentBox {
    width: 70.0239808153vw;
    border-radius: 1.7985611511vw;
    padding: 2.3980815348vw 2.3980815348vw;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 2.3980815348vw;
    margin: 0 auto;
  }
  .authenticListingsSection .cardItems .contentBox .imageSectiom {
    display: block;
    width: 8.1534772182vw;
    height: 8.1534772182vw;
    padding: 1.1990407674vw;
    background: rgb(137, 69, 236);
    border-radius: 15px;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .authenticListingsSection .cardItems .contentBox .imageSectiom img {
    width: 4.3165467626vw;
  }
  .authenticListingsSection .cardItems .contentBox h2 {
    font-size: 2.1582733813vw;
    line-height: 2.6378896882vw;
    margin: 1.4388489209vw 0;
  }
  .authenticListingsSection .cardItems .contentBox p {
    font-size: 2.0383693046vw;
    line-height: 2.3980815348vw;
    width: 55.51558753vw;
  }
  .authenticListingsSection .cardItems .contentBox.closeSection .imageSectiom {
    background: rgb(255, 0, 121);
  }
  .authenticListingsSection .cardItems .contentBox.closeSection .imageSectiom img {
    width: 4.7961630695vw;
  }
  .authenticListingsSection .fullImage {
    height: 23.5011990408vw;
    margin-bottom: 5.9952038369vw;
  }
}
@media (min-width: 1024px) {
  .authenticListingsSection {
    padding: 2.6041666667vw 0;
  }
  .authenticListingsSection p.subTitle {
    font-size: 1.0416666667vw;
    margin-bottom: 0.3125vw;
    text-align: left;
  }
  .authenticListingsSection .titleSection {
    margin-bottom: 0.3125vw;
    justify-content: center;
    align-items: center;
    justify-content: start;
  }
  .authenticListingsSection .titleSection h2 {
    font-size: 2.4479166667vw;
    width: 29.21875vw;
    text-align: left;
  }
  .authenticListingsSection .titleSection img {
    display: block;
    width: 2.0833333333vw;
    height: auto;
    margin-top: -10px;
  }
  .authenticListingsSection .descSection {
    margin-bottom: 1.5625vw;
  }
  .authenticListingsSection .descSection p {
    font-size: 1.4583333333vw;
    text-align: start;
  }
  .authenticListingsSection .cardItems {
    width: 100%;
    margin-top: 2.0833333333vw;
    gap: 1.5625vw 0.8333333333vw;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .authenticListingsSection .cardItems .contentBox {
    width: 30.4166666667vw;
    border-radius: 0.78125vw;
    padding: 1.0416666667vw 1.0416666667vw;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 1.0416666667vw;
    margin: 0;
  }
  .authenticListingsSection .cardItems .contentBox .imageSectiom {
    display: block;
    width: 3.5416666667vw;
    height: 3.5416666667vw;
    padding: 0.5208333333vw 0.8333333333vw;
    background: rgb(137, 69, 236);
    border-radius: 15px;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .authenticListingsSection .cardItems .contentBox .imageSectiom img {
    width: 2.0833333333vw;
  }
  .authenticListingsSection .cardItems .contentBox h2 {
    font-size: 0.9375vw;
    line-height: 1.1458333333vw;
    margin: 0.625vw 0;
  }
  .authenticListingsSection .cardItems .contentBox p {
    font-size: 0.8333333333vw;
    line-height: 1.0416666667vw;
    width: 24.1145833333vw;
  }
  .authenticListingsSection .cardItems .contentBox.contentBox:nth-child(1) {
    order: 1;
  }
  .authenticListingsSection .cardItems .contentBox.contentBox:nth-child(2) {
    order: 3;
  }
  .authenticListingsSection .cardItems .contentBox.contentBox:nth-child(3) {
    order: 2;
  }
  .authenticListingsSection .cardItems .contentBox.contentBox:nth-child(4) {
    order: 4;
  }
  .authenticListingsSection .cardItems .contentBox.closeSection .imageSectiom {
    background: rgb(255, 0, 121);
  }
  .authenticListingsSection .cardItems .contentBox.closeSection .imageSectiom img {
    width: 2.3958333333vw;
  }
  .authenticListingsSection .fullImage {
    width: 82%;
    height: auto;
    margin: 0 auto;
    text-align: center;
  }
  .authenticListingsSection .fullImage img {
    width: 100%;
    height: 15vw;
  }
}