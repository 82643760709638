.approvedBySection {
  padding: 0 0 0vw;
}
.approvedBySection .titleSection {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 12px;
  margin-bottom: 1.6vw;
}
.approvedBySection .titleSection h2 {
  position: relative;
  font-family: Inter;
  font-size: 8vw;
  font-weight: 700;
  line-height: normal;
  color: rgb(76, 41, 181);
  margin: 0 0 1.6vw 0;
}
.approvedBySection .titleSection h2 span {
  color: rgb(255, 0, 121);
}
.approvedBySection .titleSection h2::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 4px;
  background-color: rgb(76, 41, 181);
  bottom: -1.6vw;
}
.approvedBySection .titleSection img {
  display: none;
  width: auto;
  height: 4.2666666667vw;
}
.approvedBySection .boxContentImage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3.2vw;
  padding-top: 5.3333333333vw;
}
.approvedBySection .boxContentImage img {
  height: 16vw;
}
.approvedBySection .boxContentImage img:nth-child(1) {
  width: 18.6666666667vw;
}
.approvedBySection .boxContentImage img:nth-child(2) {
  width: 16vw;
}
.approvedBySection .boxContentImage img:nth-child(3) {
  width: 32vw;
}
.approvedBySection .boxContentImage img:nth-child(4) {
  width: 16vw;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .approvedBySection {
    padding: 0vw 0;
  }
  .approvedBySection .titleSection {
    margin-bottom: 0.7194244604vw;
    align-items: center;
    gap: 12px;
  }
  .approvedBySection .titleSection h2 {
    font-size: 4.1966426859vw;
    line-height: 5.035971223vw;
    margin: 1.1990407674vw 0 2.8776978417vw 0;
  }
  .approvedBySection .titleSection h2::before {
    bottom: -1.4388489209vw;
  }
  .approvedBySection .titleSection img {
    display: block;
    width: 4.3165467626vw;
    height: auto;
  }
  .approvedBySection .boxContentImage {
    gap: 0vw;
    padding-top: 2.3980815348vw;
    justify-content: space-between;
  }
  .approvedBySection .boxContentImage img {
    width: auto;
    height: 9.5923261391vw;
  }
  .approvedBySection .boxContentImage img:nth-child(1), .approvedBySection .boxContentImage img:nth-child(2), .approvedBySection .boxContentImage img:nth-child(3), .approvedBySection .boxContentImage img:nth-child(4) {
    width: auto;
  }
}
@media (min-width: 1024px) {
  .approvedBySection {
    padding: 0 0 2.6041666667vw;
    border-top: 1px dott red;
  }
  .approvedBySection .titleSection {
    margin-bottom: 0.3125vw;
    justify-content: center;
    align-items: center;
    justify-content: center;
  }
  .approvedBySection .titleSection h2 {
    font-size: 2.6041666667vw;
    width: auto;
    margin: 0;
  }
  .approvedBySection .titleSection h2::before {
    bottom: -0.625vw;
  }
  .approvedBySection .titleSection img {
    display: block;
    width: 2.0833333333vw;
    height: auto;
    margin-top: 0;
  }
  .approvedBySection .boxContentImage {
    width: auto;
    gap: 2.6041666667vw;
    padding-top: 2.6041666667vw;
    justify-content: space-between;
    margin: 0 auto;
  }
  .approvedBySection .boxContentImage img {
    width: calc(25% - 2.6041666667vw);
    height: auto;
    max-height: 7.8125vw;
  }
  .approvedBySection .boxContentImage img:nth-child(3) {
    height: 4.1666666667vw;
  }
  .approvedBySection .boxContentImage img:nth-child(1), .approvedBySection .boxContentImage img:nth-child(2), .approvedBySection .boxContentImage img:nth-child(3), .approvedBySection .boxContentImage img:nth-child(4) {
    width: auto;
  }
}