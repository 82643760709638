.sellYourFeesForeverSection .mobileView {
  background: linear-gradient(to top left, rgb(76, 41, 181), rgba(137, 63, 236, 0.5));
  padding: 16vw 0;
}
.sellYourFeesForeverSection .mobileView h2 {
  font-family: Inter;
  font-size: 7.4666666667vw;
  font-weight: 700;
  width: 91.7333333333vw;
  text-align: center;
  color: white;
  line-height: 8.8vw;
  margin: 0 auto;
}
.sellYourFeesForeverSection .mobileView p {
  font-family: Poppins;
  font-size: 4.2666666667vw;
  font-weight: 500;
  width: 91.7333333333vw;
  text-align: center;
  color: white;
  line-height: 8vw;
  margin: 8vw auto 0;
}
.sellYourFeesForeverSection .mobileView p span {
  font-weight: 700;
}
.sellYourFeesForeverSection .mobileViewDownSection {
  background-color: rgb(242, 242, 242);
  padding: 16vw 0;
}
.sellYourFeesForeverSection .mobileViewDownSection h2 {
  font-family: Poppins;
  font-size: 5.3333333333vw;
  font-weight: 600;
  width: 88.2666666667vw;
  text-align: center;
  color: rgb(255, 0, 121);
  line-height: 11.7333333333vw;
  margin-left: auto;
  margin-right: auto;
}
.sellYourFeesForeverSection .mobileViewDownSection a {
  width: 49.6vw;
  height: 14.6666666667vw;
  text-decoration: none;
  background: linear-gradient(to top left, rgb(76, 41, 181), rgba(137, 63, 236, 0.5));
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-size: 4.8vw;
  font-weight: 600;
  color: white;
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
}
.sellYourFeesForeverSection .mobileViewDownSection p {
  margin-top: 2.6666666667vw;
  font-family: Poppins;
  font-size: 3.7333333333vw;
  font-weight: 500;
  width: 91.7333333333vw;
  text-align: center;
  color: rgb(115, 80, 159);
  line-height: 8vw;
  margin-left: auto;
  margin-right: auto;
}
.sellYourFeesForeverSection .mobileViewDownSection p span {
  font-weight: 700;
}
.sellYourFeesForeverSection .desktopView {
  display: none;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .sellYourFeesForeverSection .mobileView {
    padding: 7.1942446043vw 0;
  }
  .sellYourFeesForeverSection .mobileView h2 {
    font-size: 4.1966426859vw;
    width: 84.0527577938vw;
    line-height: 3.5971223022vw;
  }
  .sellYourFeesForeverSection .mobileView p {
    margin-top: 3.5971223022vw;
    font-size: 1.9184652278vw;
    width: 76.8585131894vw;
    line-height: 3.5971223022vw;
  }
  .sellYourFeesForeverSection .mobileViewDownSection {
    padding: 4.7961630695vw 0;
  }
  .sellYourFeesForeverSection .mobileViewDownSection h2 {
    font-size: 2.3980815348vw;
    width: 61.5107913669vw;
    line-height: 5.2757793765vw;
  }
  .sellYourFeesForeverSection .mobileViewDownSection a {
    width: 22.3021582734vw;
    height: 6.5947242206vw;
    font-size: 2.1582733813vw;
  }
  .sellYourFeesForeverSection .mobileViewDownSection p {
    margin-top: 1.1990407674vw;
    font-size: 1.6786570743vw;
    width: 61.5107913669vw;
    line-height: 3.5971223022vw;
  }
}
@media (min-width: 1024px) {
  .sellYourFeesForeverSection .mobileView {
    display: none;
  }
  .sellYourFeesForeverSection .mobileViewDownSection {
    display: none;
  }
  .sellYourFeesForeverSection .desktopView {
    display: flex;
    background: linear-gradient(to bottom, rgb(125, 86, 209) 50%, rgb(242, 242, 242) 50%);
    padding: 2.0833333333vw;
  }
  .sellYourFeesForeverSection .desktopView .contentBox {
    width: 80%;
    margin: 0 auto;
    background-color: white;
    padding: 1.5625vw 0;
    border-radius: 8px;
  }
  .sellYourFeesForeverSection .desktopView .contentBox h2 {
    font-family: Inter;
    font-size: 2.4479166667vw;
    font-weight: 700;
    width: 40.3125vw;
    margin: 0 auto;
    text-align: center;
    color: rgb(76, 41, 181);
  }
  .sellYourFeesForeverSection .desktopView .contentBox h2 span {
    color: rgb(255, 145, 197);
  }
  .sellYourFeesForeverSection .desktopView .contentBox p.descOne {
    font-family: Poppins;
    font-size: 0.9375vw;
    font-weight: 500;
    width: 41.875vw;
    margin: 0 auto;
    text-align: center;
    color: rgb(76, 41, 181);
    margin-top: 1.0416666667vw;
  }
  .sellYourFeesForeverSection .desktopView .contentBox h3 {
    font-family: Poppins;
    font-size: 1.0416666667vw;
    font-weight: 600;
    width: 41.875vw;
    margin: 0 auto;
    text-align: center;
    color: rgb(255, 145, 197);
    margin-top: 1.0416666667vw;
  }
  .sellYourFeesForeverSection .desktopView .contentBox a {
    width: 9.6875vw;
    height: 2.8645833333vw;
    color: white;
    background: linear-gradient(to top left, rgb(76, 41, 181), rgba(137, 63, 236, 0.5));
    border-radius: 8px;
    font-family: Inter;
    font-size: 0.9375vw;
    font-weight: 600;
    margin: 0 auto;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.0416666667vw;
  }
  .sellYourFeesForeverSection .desktopView .contentBox p.termsAndConditions {
    font-family: Poppins;
    font-size: 0.7291666667vw;
    font-weight: 500;
    width: 41.875vw;
    margin: 0 auto;
    text-align: center;
    color: rgb(76, 41, 181);
    margin-top: 1.0416666667vw;
  }
}