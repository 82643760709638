.genuineSectionSection {
  padding: 8vw 0 26.6666666667vw;
}
.genuineSectionSection p.subTitle {
  font-family: Inter;
  font-size: 5.3333333333vw;
  font-weight: 500;
  margin-bottom: 1.6vw;
  text-align: center;
  color: rgb(76, 41, 181);
}
.genuineSectionSection .titleSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-bottom: 1.6vw;
}
.genuineSectionSection .titleSection h2 {
  font-family: Inter;
  font-size: 7.4666666667vw;
  font-weight: 700;
  color: rgb(76, 41, 181);
}
.genuineSectionSection .titleSection img {
  display: none;
  width: auto;
  height: 4.2666666667vw;
}
.genuineSectionSection .descSection {
  margin-bottom: 8vw;
}
.genuineSectionSection .descSection p {
  text-align: center;
  font-size: 4.8vw;
  font-family: Inter;
  font-weight: 500;
  color: rgb(115, 80, 159);
}
.genuineSectionSection .descSection p span {
  font-weight: 700;
}
.genuineSectionSection .sliders .contentBox {
  position: relative;
  border-radius: 40px;
  margin-bottom: 13.3333333333vw;
  background-color: #fff;
  background: linear-gradient(rgba(137, 69, 236, 0.04), rgba(137, 69, 236, 0));
  width: 90%;
  margin: 0 auto;
}
.genuineSectionSection .sliders .contentBox .boxShadow {
  border-radius: 40px;
  box-shadow: 0px 4px 60px 6px rgba(137, 69, 236, 0.14);
  border: 2px solid rgb(233, 233, 233);
  height: 106.6666666667vw;
  text-align: center;
  padding: 10.6666666667vw 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.genuineSectionSection .sliders .contentBox .boxShadow img {
  width: 20vw;
  height: auto;
  margin-bottom: 8vw;
}
.genuineSectionSection .sliders .contentBox .boxShadow img.fullSlider {
  width: 48vw;
  height: auto;
}
.genuineSectionSection .sliders .contentBox .boxShadow p.title {
  font-family: Inter;
  font-size: 6.4vw;
  font-weight: 700;
  line-height: 7.7333333333vw;
}
.genuineSectionSection .sliders .contentBox .boxShadow p.descBox {
  font-family: Inter;
  font-size: 5.3333333333vw;
  font-weight: 400;
  line-height: 8vw;
  color: rgb(158, 157, 157);
  width: 80vw;
}
.genuineSectionSection .sliders .swiper-pagination-bullet {
  display: none;
}
.genuineSectionSection .sliders .swiper-button-prev,
.genuineSectionSection .sliders .swiper-button-next {
  display: none;
}
.genuineSectionSection .arrowSliders {
  position: relative;
}
.genuineSectionSection .arrowSliders .containerBox {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 5.3333333333vw;
  display: flex;
  gap: 5.3333333333vw;
}
.genuineSectionSection .arrowSliders .containerBox .swiper-button-prevStyle,
.genuineSectionSection .arrowSliders .containerBox .swiper-button-nextStyle {
  cursor: pointer;
  width: 20px;
  height: 20px;
  background: rgb(224, 224, 224);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  padding: 16px;
  border: 2px solid rgb(224, 224, 224);
}
.genuineSectionSection .arrowSliders .containerBox .swiper-button-prevStyle:hover,
.genuineSectionSection .arrowSliders .containerBox .swiper-button-nextStyle:hover {
  background: white;
}
.genuineSectionSection .arrowSliders .containerBox .swiper-button-prevStyle {
  transform: rotate(180deg);
}

@media (min-width: 768px) and (max-width: 1023px) {
  .genuineSectionSection {
    padding: 11.9904076739vw 0;
  }
  .genuineSectionSection p.subTitle {
    font-size: 2.3980815348vw;
    margin-bottom: 0.7194244604vw;
  }
  .genuineSectionSection .titleSection {
    margin-bottom: 0.7194244604vw;
  }
  .genuineSectionSection .titleSection h2 {
    font-size: 4.1966426859vw;
  }
  .genuineSectionSection .titleSection img {
    display: block;
    height: 3.5971223022vw;
  }
  .genuineSectionSection .descSection {
    margin-bottom: 3.5971223022vw;
  }
  .genuineSectionSection .descSection p {
    font-size: 2.6378896882vw;
  }
  .genuineSectionSection .sliders .contentBox {
    margin-bottom: 5.9952038369vw;
    width: 90%;
    margin: 0 auto;
  }
  .genuineSectionSection .sliders .contentBox .boxShadow {
    height: 82.7338129496vw;
    padding: 7.1942446043vw 0;
  }
  .genuineSectionSection .sliders .contentBox .boxShadow img {
    width: 23.9808153477vw;
    margin-bottom: 3.5971223022vw;
  }
  .genuineSectionSection .sliders .contentBox .boxShadow img.fullSlider {
    width: 35.9712230216vw;
  }
  .genuineSectionSection .sliders .contentBox .boxShadow p.title {
    font-size: 4.1966426859vw;
    line-height: 5.035971223vw;
  }
  .genuineSectionSection .sliders .contentBox .boxShadow p.descBox {
    font-size: 2.3980815348vw;
    line-height: 3.5971223022vw;
    width: 59.2326139089vw;
  }
  .genuineSectionSection .sliders .swiper-pagination-bullet {
    display: none;
  }
  .genuineSectionSection .arrowSliders {
    position: relative;
  }
  .genuineSectionSection .arrowSliders .containerBox {
    transform: translateX(-50%);
    top: 2.3980815348vw;
    gap: 2.3980815348vw;
  }
}
@media (min-width: 1024px) {
  .genuineSectionSection {
    padding: 2.6041666667vw 0;
    position: relative;
  }
  .genuineSectionSection p.subTitle {
    font-size: 1.0416666667vw;
    margin-bottom: 0.3125vw;
    text-align: start;
  }
  .genuineSectionSection .titleSection {
    margin-bottom: 0.3125vw;
    justify-content: start;
    align-items: center;
  }
  .genuineSectionSection .titleSection h2 {
    font-size: 2.4479166667vw;
  }
  .genuineSectionSection .titleSection img {
    display: block;
    width: 2.0833333333vw;
    height: auto;
    margin-top: -10px;
  }
  .genuineSectionSection .descSection {
    margin-bottom: 5.2083333333vw;
  }
  .genuineSectionSection .descSection p {
    font-size: 1.4583333333vw;
    text-align: start;
  }
  .genuineSectionSection .arrowSliders {
    position: relative;
  }
  .genuineSectionSection .arrowSliders .containerBox {
    position: absolute;
    left: 94%;
    top: -50px;
    display: flex;
    gap: 1.0416666667vw;
  }
  .genuineSectionSection .arrowSliders .containerBox .swiper-button-prevStyle,
  .genuineSectionSection .arrowSliders .containerBox .swiper-button-nextStyle {
    cursor: pointer;
    width: 20px;
    height: 20px;
    background: rgb(224, 224, 224);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    padding: 16px;
    border: 2px solid rgb(224, 224, 224);
  }
  .genuineSectionSection .arrowSliders .containerBox .swiper-button-prevStyle:hover,
  .genuineSectionSection .arrowSliders .containerBox .swiper-button-nextStyle:hover {
    background: white;
  }
  .genuineSectionSection .arrowSliders .containerBox .swiper-button-prevStyle {
    transform: rotate(180deg);
  }
  .genuineSectionSection .sliders .contentBox {
    margin-bottom: 2.6041666667vw;
    width: 82%;
    margin: 0 auto;
  }
  .genuineSectionSection .sliders .contentBox .boxShadow {
    height: 35.9375vw;
    padding: 3.125vw 0;
  }
  .genuineSectionSection .sliders .contentBox .boxShadow img {
    width: 10.4166666667vw;
    margin-bottom: 1.5625vw;
  }
  .genuineSectionSection .sliders .contentBox .boxShadow img.fullSlider {
    width: 15.625vw;
  }
  .genuineSectionSection .sliders .contentBox .boxShadow p.title {
    font-size: 1.8229166667vw;
    line-height: 2.1875vw;
  }
  .genuineSectionSection .sliders .contentBox .boxShadow p.descBox {
    font-size: 1.0416666667vw;
    line-height: 1.5625vw;
    width: 30.8333333333vw;
  }
  .genuineSectionSection .sliders .swiper-pagination-bullet {
    display: none;
  }
  .genuineSectionSection .arrowSliders {
    position: absolute;
    top: 25%;
    left: 86%;
  }
  .genuineSectionSection .arrowSliders .containerBox {
    top: 0vw;
    gap: 1.0416666667vw;
  }
}