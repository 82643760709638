.launchingSoonll {
  padding: 10.6666666667vw 0 0;
}
.launchingSoonll p.subTitle {
  font-family: Inter;
  font-size: 4.8vw;
  font-weight: 500;
  margin-bottom: 0vw;
  text-align: center;
  color: rgb(115, 80, 159);
}
.launchingSoonll .titleSection {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 12px;
  margin-bottom: 1.6vw;
}
.launchingSoonll .titleSection h2 {
  font-family: Inter;
  font-size: 5.8666666667vw;
  font-weight: 700;
  line-height: normal;
  color: rgb(76, 41, 181);
  margin: 0 0 1.6vw 0;
  text-align: center;
}
.launchingSoonll .titleSection img {
  display: none;
  width: auto;
  height: 4.2666666667vw;
}
.launchingSoonll .descSection {
  margin-bottom: 8vw;
}
.launchingSoonll .descSection p {
  font-family: Inter;
  font-size: 4.2666666667vw;
  font-weight: 400;
  line-height: 5.8666666667vw;
  color: rgb(115, 80, 159);
  width: auto;
  text-align: center;
}
.launchingSoonll .descSection p span {
  font-weight: 700;
}

.fullImageBoxContentlaunchingSoonll {
  margin-bottom: 13.3333333333vw;
  background-image: url("../../assets/NewHomePage/LaunchingSoonBg.svg");
  width: 100%;
  height: 53.3333333333vw;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fullImageBoxContentlaunchingSoonll .comingSoonCount .count {
  display: flex;
}
.fullImageBoxContentlaunchingSoonll .comingSoonCount .count span {
  padding: 0 1.0666666667vw;
  font-size: 10.1333333333vw;
  color: white;
}
.fullImageBoxContentlaunchingSoonll .comingSoonCount .count .fixedForEveryone {
  display: flex;
  gap: 2.1333333333vw;
}
.fullImageBoxContentlaunchingSoonll .comingSoonCount .count .fixedForEveryone span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 11.2vw;
  height: 16.5333333333vw;
  border: 2px solid rgb(137, 69, 236);
  border-radius: 10px;
  background-color: white;
  font-family: Inter;
  font-size: 10.1333333333vw;
  color: rgb(154, 97, 238);
}

@media (min-width: 768px) and (max-width: 1023px) {
  .launchingSoonll {
    padding: 5.9952038369vw 0;
  }
  .launchingSoonll p.subTitle {
    font-size: 2.3980815348vw;
    margin-bottom: 0.7194244604vw;
  }
  .launchingSoonll .titleSection {
    margin-bottom: 0.7194244604vw;
    align-items: center;
    gap: 12px;
  }
  .launchingSoonll .titleSection h2 {
    font-size: 2.6378896882vw;
    line-height: 5.035971223vw;
    margin: 0;
  }
  .launchingSoonll .titleSection img {
    display: block;
    width: 6.7146282974vw;
    height: auto;
  }
  .launchingSoonll .descSection {
    margin: 2.3980815348vw 0 3.5971223022vw;
  }
  .launchingSoonll .descSection p {
    width: auto;
    margin: 0 auto;
    font-size: 2.3980815348vw;
    margin-bottom: 0.7194244604vw;
    line-height: 3.1175059952vw;
  }
  .fullImageBoxContentlaunchingSoonll {
    margin-bottom: 5.9952038369vw;
    margin-top: -4.1666666667vw;
    height: 23.9808153477vw;
  }
  .fullImageBoxContentlaunchingSoonll .comingSoonCount .count span {
    padding: 0 0.479616307vw;
    font-size: 4.5563549161vw;
  }
  .fullImageBoxContentlaunchingSoonll .comingSoonCount .count .fixedForEveryone {
    gap: 0.9592326139vw;
  }
  .fullImageBoxContentlaunchingSoonll .comingSoonCount .count .fixedForEveryone span {
    width: 5.035971223vw;
    height: 7.4340527578vw;
    font-size: 4.5563549161vw;
  }
}
@media (min-width: 1024px) {
  .launchingSoonll {
    padding: 2.6041666667vw 0;
  }
  .launchingSoonll p.subTitle {
    font-size: 1.0416666667vw;
    margin-bottom: 0.625vw;
    text-align: start;
  }
  .launchingSoonll .titleSection {
    margin-bottom: 0.3125vw;
    justify-content: center;
    align-items: center;
    justify-content: start;
    gap: 0;
  }
  .launchingSoonll .titleSection h2 {
    font-size: 2.0833333333vw;
    width: auto;
    margin: 0;
  }
  .launchingSoonll .titleSection img {
    display: block;
    width: 3.6458333333vw;
    height: auto;
    margin-top: 0;
  }
  .launchingSoonll .descSection {
    margin-bottom: 1.0416666667vw;
  }
  .launchingSoonll .descSection p {
    text-align: left;
    font-size: 1.25vw;
    line-height: 2.0833333333vw;
    width: auto;
    margin: 1.0416666667vw auto 3.125vw;
  }
  .fullImageBoxContentlaunchingSoonll {
    margin-bottom: 5.2083333333vw !important;
    margin-top: -4.1666666667vw !important;
    width: 81.25vw;
    height: 18.2291666667vw;
    margin: 0 auto;
    background-image: url("../../assets/NewHomePage/LaunchingSoonBgDesktop.svg");
  }
  .fullImageBoxContentlaunchingSoonll .comingSoonCount .count span {
    padding: 0 0.625vw;
    font-size: 3.0208333333vw;
  }
  .fullImageBoxContentlaunchingSoonll .comingSoonCount .count .fixedForEveryone {
    gap: 0.4166666667vw;
  }
  .fullImageBoxContentlaunchingSoonll .comingSoonCount .count .fixedForEveryone span {
    width: 5.2083333333vw;
    height: 6.7708333333vw;
    font-size: 5.2083333333vw;
    border-width: 4px;
  }
}