.professionalListingSection {
  padding: 13.3333333333vw 0;
  position: relative;
}
.professionalListingSection p.subTitle {
  font-family: Inter;
  font-size: 5.3333333333vw;
  font-weight: 500;
  margin-bottom: 1.6vw;
  text-align: center;
  color: rgb(76, 41, 181);
}
.professionalListingSection .titleSection {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 12px;
  margin-bottom: 1.6vw;
}
.professionalListingSection .titleSection h2 {
  font-family: Inter;
  font-size: 7.4666666667vw;
  font-weight: 700;
  color: rgb(76, 41, 181);
}
.professionalListingSection .titleSection img {
  display: none;
  width: auto;
  height: 4.2666666667vw;
}
.professionalListingSection .descSection {
  margin-bottom: 8vw;
}
.professionalListingSection .descSection p {
  text-align: center;
  font-size: 4.8vw;
  font-family: Inter;
  font-weight: 500;
  color: rgb(115, 80, 159);
}
.professionalListingSection .descSection p span {
  font-weight: 700;
}
.professionalListingSection .cardItem {
  background-color: white;
  box-shadow: 0 0 67px rgba(137, 69, 236, 0.17);
  padding: 3.2vw;
  border-radius: 6.9333333333vw;
}
.professionalListingSection .cardItem .sizeBox {
  background: linear-gradient(to top left, rgb(76, 41, 181), rgba(106, 63, 236, 0.5));
  border-radius: 6.9333333333vw;
  cursor: pointer;
}
.professionalListingSection .cardItem .sizeBox .contentBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10.6666666667vw 0;
}
.professionalListingSection .cardItem .sizeBox .contentBox:nth-child(1) {
  border-top-left-radius: 6.9333333333vw;
  border-top-right-radius: 6.9333333333vw;
}
.professionalListingSection .cardItem .sizeBox .contentBox:nth-child(5) {
  border-bottom-left-radius: 6.9333333333vw;
  border-bottom-right-radius: 6.9333333333vw;
}
.professionalListingSection .cardItem .sizeBox .contentBox img {
  width: 12.8vw;
  height: auto;
}
.professionalListingSection .cardItem .sizeBox .contentBox h3 {
  font-family: Inter;
  font-size: 6.4vw;
  font-weight: 700;
  padding: 3.2vw 0;
  margin: 0;
  color: white;
}
.professionalListingSection .cardItem .sizeBox .contentBox p {
  font-family: Inter;
  font-size: 4.2666666667vw;
  font-weight: 400;
  width: 53.3333333333vw;
  text-align: center;
  margin: 0;
  color: white;
  line-height: 5.3333333333vw;
}
.professionalListingSection .cardItem .sizeBox .contentBox .imageHover {
  width: 18.1333333333vw;
  height: 18.1333333333vw;
  border: 2px solid white;
  border-radius: 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.professionalListingSection .cardItem .sizeBox .contentBox .imageHover img {
  width: 8.5333333333vw;
  height: auto;
}
.professionalListingSection .cardItem .sizeBox .contentBox:hover {
  background-color: rgb(255, 0, 121);
}

@media (min-width: 768px) and (max-width: 1023px) {
  .professionalListingSection {
    padding: 5.9952038369vw 0;
  }
  .professionalListingSection p.subTitle {
    font-size: 2.3980815348vw;
    margin-bottom: 0.7194244604vw;
  }
  .professionalListingSection .titleSection {
    margin-bottom: 0.7194244604vw;
  }
  .professionalListingSection .titleSection h2 {
    font-size: 4.1966426859vw;
  }
  .professionalListingSection .titleSection img {
    display: block;
    height: 1.9184652278vw;
  }
  .professionalListingSection .descSection {
    margin-bottom: 3.5971223022vw;
  }
  .professionalListingSection .descSection p {
    font-size: 2.6378896882vw;
  }
  .professionalListingSection .cardItem {
    padding: 1.4388489209vw;
    border-radius: 3.1175059952vw;
  }
  .professionalListingSection .cardItem .sizeBox {
    border-radius: 3.1175059952vw;
  }
  .professionalListingSection .cardItem .sizeBox .contentBox {
    padding: 4.7961630695vw 0;
  }
  .professionalListingSection .cardItem .sizeBox .contentBox:nth-child(1) {
    border-top-left-radius: 3.1175059952vw;
    border-top-right-radius: 3.1175059952vw;
  }
  .professionalListingSection .cardItem .sizeBox .contentBox:nth-child(5) {
    border-bottom-left-radius: 3.1175059952vw;
    border-bottom-right-radius: 3.1175059952vw;
  }
  .professionalListingSection .cardItem .sizeBox .contentBox img {
    width: 8.1534772182vw;
  }
  .professionalListingSection .cardItem .sizeBox .contentBox h3 {
    font-size: 2.8776978417vw;
    padding: 1.4388489209vw 0;
  }
  .professionalListingSection .cardItem .sizeBox .contentBox p {
    font-size: 1.9184652278vw;
    width: 23.9808153477vw;
    line-height: 2.3980815348vw;
  }
  .professionalListingSection .cardItem .sizeBox .contentBox .imageHover {
    width: 8.1534772182vw;
    height: 8.1534772182vw;
    border-radius: 1.7985611511vw;
  }
  .professionalListingSection .cardItem .sizeBox .contentBox .imageHover img {
    width: 3.8369304556vw;
  }
}
@media (min-width: 1024px) {
  .professionalListingSection {
    padding: 2.6041666667vw 0;
  }
  .professionalListingSection p.subTitle {
    font-size: 1.0416666667vw;
    margin-bottom: 0.3125vw;
    text-align: start;
  }
  .professionalListingSection .titleSection {
    margin-bottom: 0.3125vw;
    justify-content: start;
    align-items: center;
  }
  .professionalListingSection .titleSection h2 {
    font-size: 2.4479166667vw;
  }
  .professionalListingSection .titleSection img {
    display: block;
    width: 1.6666666667vw;
    height: auto;
    margin-top: -10px;
  }
  .professionalListingSection .descSection {
    margin-bottom: 3.125vw;
  }
  .professionalListingSection .descSection p {
    font-size: 1.4583333333vw;
    text-align: start;
  }
  .professionalListingSection .cardItem {
    padding: 0.625vw;
    border-radius: 2.6041666667vw;
  }
  .professionalListingSection .cardItem .sizeBox {
    border-radius: 2.6041666667vw;
    display: flex;
    justify-content: space-around;
    max-height: 13.5416666667vw;
  }
  .professionalListingSection .cardItem .sizeBox .contentBox {
    padding: 2.0833333333vw 0;
    width: 20%;
    border-radius: 2.0833333333vw 2.0833333333vw 0 0;
  }
  .professionalListingSection .cardItem .sizeBox .contentBox img {
    width: 3.5416666667vw;
  }
  .professionalListingSection .cardItem .sizeBox .contentBox h3 {
    font-size: 1.25vw;
    padding: 0.625vw 0;
  }
  .professionalListingSection .cardItem .sizeBox .contentBox p {
    font-size: 0.8333333333vw;
    width: 10.4166666667vw;
    line-height: 1.0416666667vw;
  }
  .professionalListingSection .cardItem .sizeBox .contentBox:nth-child(1) {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    border-bottom-left-radius: 2.0833333333vw;
  }
  .professionalListingSection .cardItem .sizeBox .contentBox:nth-child(5) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-right-radius: 2.0833333333vw;
  }
  .professionalListingSection .cardItem .sizeBox .contentBox .imageHover {
    width: 3.5416666667vw;
    height: 3.5416666667vw;
    border-radius: 0.78125vw;
  }
  .professionalListingSection .cardItem .sizeBox .contentBox .imageHover img {
    width: 1.6666666667vw;
  }
  .professionalListingSection .cardItem .sizeBox .contentBox:hover {
    position: relative;
    top: -2.6041666667vw;
    height: 16.1458333333vw;
    transition: all 0.3s ease-in-out;
  }
}