.fixedSellingPriceSection {
  background: linear-gradient(to top left, rgba(76, 41, 181, 0.83), rgba(137, 69, 236, 0.41)), url("../../assets/FixedSellingPriceBg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5.3333333333vw 0 8vw 0;
}
.fixedSellingPriceSection p.subTitle {
  font-family: Inter;
  font-size: 5.3333333333vw;
  font-weight: 500;
  margin-bottom: 1.6vw;
  text-align: center;
  color: white;
}
.fixedSellingPriceSection .titleSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-bottom: 1.6vw;
}
.fixedSellingPriceSection .titleSection h2 {
  font-family: Inter;
  font-size: 7.4666666667vw;
  font-weight: 700;
  color: white;
}
.fixedSellingPriceSection .titleSection img {
  width: auto;
  height: 5.8666666667vw;
}
.fixedSellingPriceSection .descSection {
  width: 86.6666666667vw;
  margin: 0 auto 16vw;
}
.fixedSellingPriceSection .descSection p {
  text-align: center;
  font-size: 4.8vw;
  font-family: Inter;
  font-weight: 400;
  color: white;
}
.fixedSellingPriceSection .withoutTheGenuine {
  text-align: center;
  margin-bottom: 10.6666666667vw;
}
.fixedSellingPriceSection .withoutTheGenuine img {
  width: 42.6666666667vw;
  height: auto;
  margin-bottom: 5.3333333333vw;
}
.fixedSellingPriceSection .withoutTheGenuine p {
  margin: 0;
  font-size: 5.3333333333vw;
  font-family: Inter;
  font-weight: 700;
  color: white;
}
.fixedSellingPriceSection .withoutTheGenuine p img {
  width: 5.3333333333vw;
  height: auto;
  margin: -1.3333333333vw 2.6666666667vw 0 2.6666666667vw;
}
.fixedSellingPriceSection .descDown {
  display: none;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .fixedSellingPriceSection {
    padding: 2.3980815348vw 0 3.5971223022vw 0;
  }
  .fixedSellingPriceSection p.subTitle {
    font-size: 2.3980815348vw;
    margin-bottom: 0.7194244604vw;
  }
  .fixedSellingPriceSection .titleSection {
    margin-bottom: 0.7194244604vw;
  }
  .fixedSellingPriceSection .titleSection h2 {
    font-size: 4.1966426859vw;
  }
  .fixedSellingPriceSection .titleSection img {
    width: auto;
    height: 4.3165467626vw;
  }
  .fixedSellingPriceSection .descSection {
    margin-bottom: 7.1942446043vw;
    width: 60.071942446vw;
  }
  .fixedSellingPriceSection .descSection p {
    font-size: 2.6378896882vw;
  }
  .fixedSellingPriceSection .withoutTheGenuine {
    margin-bottom: 4.7961630695vw;
  }
  .fixedSellingPriceSection .withoutTheGenuine img {
    width: 29.9760191847vw;
    margin-bottom: 2.3980815348vw;
  }
  .fixedSellingPriceSection .withoutTheGenuine p {
    font-size: 3.7170263789vw;
  }
  .fixedSellingPriceSection .withoutTheGenuine p img {
    width: 3.3573141487vw;
    margin: -0.5995203837vw 1.4388489209vw 0 1.4388489209vw;
  }
}
@media (min-width: 1024px) {
  .fixedSellingPriceSection {
    padding: 3.125vw 0 3.125vw 0;
  }
  .fixedSellingPriceSection p.subTitle {
    font-size: 1.0416666667vw;
    margin-bottom: 0.3125vw;
  }
  .fixedSellingPriceSection .titleSection {
    margin-bottom: 0.3125vw;
  }
  .fixedSellingPriceSection .titleSection h2 {
    font-size: 3.6458333333vw;
  }
  .fixedSellingPriceSection .titleSection img {
    width: auto;
    height: 1.875vw;
  }
  .fixedSellingPriceSection .descSection {
    margin-bottom: 6.25vw;
    width: 57.2916666667vw;
  }
  .fixedSellingPriceSection .descSection p {
    font-size: 1.4583333333vw;
  }
  .fixedSellingPriceSection .descImage {
    display: flex;
    justify-content: space-evenly;
    align-items: end;
  }
  .fixedSellingPriceSection .descImage .withoutTheGenuine {
    margin-bottom: 6.25vw;
  }
  .fixedSellingPriceSection .descImage .withoutTheGenuine img {
    width: 18.2291666667vw;
    margin-bottom: 1.0416666667vw;
  }
  .fixedSellingPriceSection .descImage .withoutTheGenuine img.bigSizeImage {
    width: 20.8333333333vw;
    height: auto;
  }
  .fixedSellingPriceSection .descImage .withoutTheGenuine p {
    font-size: 1.6145833333vw;
  }
  .fixedSellingPriceSection .descImage .withoutTheGenuine p img {
    width: 1.4583333333vw;
    margin: -0.2604166667vw 0.625vw 0 0.625vw;
  }
  .fixedSellingPriceSection .descDown {
    width: 57.2916666667vw;
    margin: 0 auto;
    display: block;
    font-family: Inter;
    font-size: 1.25vw;
    line-height: 1.875vw;
    font-weight: 400;
    text-align: center;
    color: white;
  }
}